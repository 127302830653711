/**
 * Naming convention
 * componentName[.subComponentName.]description.type OR
 * page[.subpage.]component.description.type
 */

const SQUARE_FEET_ENGLISH = "sq. ft.";
const SQUARE_FEET_FRENCH = "pi²";
export const PLACEHOLDER = "--";

const numberWithSuffix = (field, suffix) => {
  if (suffix) {
    return `{${field}, number} ${suffix}`;
  }
  return `{${field}, number}`;
};

const numberWithPlaceholder = (field, suffix, placeholder = PLACEHOLDER) => {
  return `{${field}, plural, =null {${placeholder}} other {${numberWithSuffix(
    field,
    suffix
  )}}}`;
};

const messages = {
  en: {
    /// ENGLISH CODES
    "codes.listing-status.L": "Listed",
    "codes.listing-status.C": "Call For Availability",
    "codes.listing-status.F": "Fully Leased",

    "codes.building-type.O": "Office",
    "codes.building-type.E": "Residential",
    "codes.building-type.R": "Retail",
    "codes.building-type.I": "Industrial",
    "codes.building-type.L": "Land",

    "codes.buildings.pub-status.P": "Published",
    "codes.buildings.pub-status.NP": "Not Published",

    "codes.buildings.fav-status.F": "Favourites",
    "codes.buildings.fav-status.NF": "Not Favourites",

    "codes.buildings.archived-status.A": "Archived",
    "codes.buildings.archived-status.NA": "Not Archived",

    "codes.suite-type.I": "Industrial",
    "codes.suite-type.O": "Office",
    "codes.suite-type.E": "Residential",
    "codes.suite-type.R": "Retail",
    "codes.suite-type.S": "Retirement",
    "codes.suite-type.Z": "Other Leasable",
    "codes.suite-type.N": "Non Leasable",
    "codes.suite-type.M": "Mixed Use",
    "codes.suite-type.A": "All Assets",
    "codes.suite-type.L":"Land",

    "codes.suite-availability-type.P": "Public",
    "codes.suite-availability-type.PR": "Private",
    "codes.suite-availability-type.L": "Leased",
    "codes.suite-availability-type.M": "Month-To-Month",
    "codes.suite-availability-type.C": "Committed",
    "codes.suite-availability-type.PE": "Pending",

    "codes.suite-state.C": "Committed",
    "codes.suite-state.L": "Leased",

    "codes.lease.availability.leased": "Leased",
    "codes.lease.availability.m2m": "Month-to-Month",
    "codes.lease.availability.pending": "Pending",
    "codes.lease.availability.expired": "Expired",

    "codes.residential-suite-type.B": "Bachelor",
    "codes.residential-suite-type.1": "One Bedroom",
    "codes.residential-suite-type.2": "Two Bedroom",
    "codes.residential-suite-type.3": "Three Bedroom",
    "codes.residential-suite-type.4": "Four Bedroom",
    "codes.residential-suite-type.5": "Five Bedroom+",
    "codes.residential-suite-type.T": "Townhouse",

    "codes.suite-condition-type.F": "Fully Built Out",
    "codes.suite-condition-type.7": "75 % Complete",
    "codes.suite-condition-type.5": "50 % Complete",
    "codes.suite-condition-type.1": "10 % Complete",
    "codes.suite-condition-type.B": "Base Building",

    "codes.residential-building-type.C": "Condominium",
    "codes.residential-building-type.L": "Lowrise",
    "codes.residential-building-type.M": "Midrise",
    "codes.residential-building-type.H": "Highrise",
    "codes.residential-building-type.T": "Townhouse",
    "codes.residential-building-type.B": "Mobile",

    "codes.user-types.AA": "Arcestra Administrator",
    "codes.user-types.CA": "Company Administrator",
    "codes.user-types.LA": "Leasing Administrator",
    "codes.user-types.LB": "Leasing Broker",

    "codes.exterior-wall-types.B": "Brick",
    "codes.exterior-wall-types.S": "Stone",
    "codes.exterior-wall-types.M": "Metal",
    "codes.exterior-wall-types.G": "Glass",
    "codes.exterior-wall-types.T": "Stucco",
    "codes.exterior-wall-types.P": "Pre-Cast Slab Concrete",
    "codes.exterior-wall-types.O": "Other",

    "codes.roofing-types.M": "Metal",
    "codes.roofing-types.B": "Membrane",
    "codes.roofing-types.T": "Tile",
    "codes.roofing-types.C": "Concrete",
    "codes.roofing-types.O": "Other",

    "codes.boma-types.P": "BOMA - Platinum",
    "codes.boma-types.G": "BOMA - Gold",
    "codes.boma-types.S": "BOMA - Silver",
    "codes.boma-types.B": "BOMA - Bronze",
    "codes.boma-types.C": "BOMA - Certified",

    "codes.bomasustainable-types.P": "BOMA Sustainable - Platinum",
    "codes.bomasustainable-types.G": "BOMA Sustainable - Gold",
    "codes.bomasustainable-types.S": "BOMA Sustainable - Silver",
    "codes.bomasustainable-types.B": "BOMA Sustainable - Bronze",
    "codes.bomasustainable-types.C": "BOMA Sustainable - Baseline",

    "codes.leed-types.P": "LEED - Platinum",
    "codes.leed-types.G": "LEED - Gold",
    "codes.leed-types.S": "LEED - Silver",
    "codes.leed-types.C": "LEED - Certified",
    "codes.leed-types.L": "LEED - Certified Project",
    "codes.leed-types.Z": "LEED - Leed Zero",

    "codes.wired-types.P": "WiredScore - Platinum",
    "codes.wired-types.G": "WiredScore - Gold",
    "codes.wired-types.S": "WiredScore - Silver",
    "codes.wired-types.C": "WiredScore - Certified",

    "codes.smartscore-types.P": "SmartScore - Platinum",
    "codes.smartscore-types.G": "SmartScore - Gold",
    "codes.smartscore-types.S": "SmartScore - Silver",
    "codes.smartscore-types.C": "SmartScore - Certified",

    "codes.fitwel-types.F": "Fitwel",
    "codes.gresb-types.G": "GRESB",

    "codes.energystar-types.E": "ENERGY STAR",
    "codes.zerocarbon-types.Z": "ZERO CARBON",
    "codes.well-types.W": "WELL Health and Safety Rating",
    "codes.rhfac-types.R": "RHFAC",
    "codes.well2023-types.X": "WELL 2023",
    "codes.bomatoby-types.K": "BOMA TOBY",
    "codes.bomaexcellence-types.M": "BOMA EXCELLENCE",

    "codes.zoned-types.C": "Commercial",
    "codes.zoned-types.I": "Industrial",
    "codes.zoned-types.R": "Retail",
    "codes.zoned-types.E": "Residential",
    "codes.zoned-types.N": "Institutional",
    "codes.zoned-types.O": "Other Leasable",

    "codes.sale-or-lease-types.S": "Sale",
    "codes.sale-or-lease-types.L": "Lease",

    "codes.land-types.R": "Rural",
    "codes.land-types.U": "Urban",
    "codes.land-types.C": "Recreational",

    "codes.property-sub-types.C": "Commercial",
    "codes.property-sub-types.R": "Recreational",
    "codes.property-sub-types.I": "Industrial",
    "codes.property-sub-types.E": "Retail",

    "codes.proposed-use-types.I": "Industrial",
    "codes.proposed-use-types.O": "Office",
    "codes.proposed-use-types.R": "Retail",
    "codes.proposed-use-types.E": "Residential",
    "codes.proposed-use-types.C": "Recreational",

    "codes.permitted-use-types.R": "Retail",
    "codes.permitted-use-types.I": "Industrial",
    "codes.permitted-use-types.E": "Residential",
    "codes.permitted-use-types.C": "Commercial",
    "codes.permitted-use-types.U": "Rural",

    "codes.zoning-types.I": "Industrial",
    "codes.zoning-types.O": "Office",
    "codes.zoning-types.C": "Commercial",
    "codes.zoning-types.R": "Residential",
    "codes.zoning-types.A": "Agricultural",
    "codes.zoning-types.M": "Mixed",

    "codes.frontage-types.U": "County",
    "codes.frontage-types.C": "City",
    "codes.frontage-types.P": "Private",

    "codes.surfacing-types.A": "Asphalt",
    "codes.surfacing-types.G": "Gravel",
    "codes.surfacing-types.C": "Concrete",

    "codes.waterfront-types.L": "Lake",
    "codes.waterfront-types.O": "Ocean",
    "codes.waterfront-types.R": "River",
    "codes.waterfront-types.P": "Pond",

    "codes.class-types.3": "AAA",
    "codes.class-types.A": "A",
    "codes.class-types.B": "B",
    "codes.class-types.C": "C",

    "codes.interior-wall-types.D": "Dry Wall",
    "codes.interior-wall-types.W": "Wood",
    "codes.interior-wall-types.S": "Stone",
    "codes.interior-wall-types.B": "Brick",
    "codes.interior-wall-types.O": "Other",

    "codes.lease-terms.F": "Flexible",
    "codes.lease-terms.L": "Less Than One Year",
    "codes.lease-terms.M": "Monthly",
    "codes.lease-terms.Y": "Yearly",

    "codes.notice-period.N": "None",
    "codes.notice-period.3": "30 Days",
    "codes.notice-period.6": "60 Days",
    "codes.notice-period.9": "90 Days",
    "codes.notice-period.1": "120 Days",
    "codes.notice-period.8": "180 Days",

    "codes.tenant-rights.right_of_first_refusal": "Right of First Refusal",
    "codes.tenant-rights.right_of_first_offer": "Right of First Offer",
    "codes.tenant-rights.right_of_relocation": "Right of Relocation",
    "codes.tenant-rights.right_of_signage": "Right of Signage",
    "codes.tenant-rights.right_of_early_termination":
      "Right of Early Termination",
    "codes.tenant-rights.right_of_audit": "Right of Audit",
    "codes.tenant-rights.right_of_exclusivity": "Right of Exclusivity",
    "codes.tenant-rights.right_of_contraction": "Right of Contraction",
    "codes.tenant-rights.right_of_expansion": "Right of Expansion",
    "codes.tenant-rights.right_of_renewal": "Right of Renewal",
    "codes.tenant-rights.right_of_gross_up": "Right of Gross Up",

    "codes.mall-types.N": "Neighborhood Centre",
    "codes.mall-types.R": "Regional Centre",
    "codes.mall-types.S": "Super Regional Centre",
    "codes.mall-types.P": "Power Centre",
    "codes.mall-types.O": "Other",

    "codes.measurement-units.I": "Imperial",
    "codes.measurement-units.M": "Metric",

    "codes.media-tags.T": "Thumbnail",
    "codes.media-tags.H": "Hero",
    "codes.media-tags.P": "Floor Plan",
    "codes.media-tags.B": "Brochure",
    "codes.media-tags.3D": "3D",
    "codes.media-tags.SP": "Site Plan",

    "codes.suite-visibility-types.PB": "Published",
    "codes.suite-visibility-types.PR": "Private",

    // Common App Messages
    "network-error": "An error has occurred. Please try again.",
    "building-not-found-error-title":
      "Sorry, the building you're looking for cannot be found.",
    "building-not-found-error-content":
      "The link you clicked may be broken or the page may have been removed.",
    "building-not-found-error-button": "Browse All Buildings",

    // Form components
    "form.range-slider.min.label": "Min",
    "form.range-slider.max.label": "Max",
    "form.switch.off.label": "Off",
    "form.switch.on.label": "On",
    "checkbox.select-all.label": "Select All",
    "form.google-map.input.placeholder": "Enter the building address...",

    // Common Validation Messages
    "common.form.validation.required": "Required",
    "common.form.validation.map-location-required":
      "Please select an area on the map",
    "common.form.validation.password-rules":
      "Password must contain at least 1 special character from the list [ ! @ # $ % ^ & * ], 1 number, 1 capital letter and have a minimum length of 10 characters",
    "common.form.validation.username-rules": "Username already exists",
    "form.validators.errors.notANumber": "is not a number",
    "form.validators.errors.notAnInteger": "is not an integer",
    "common.alert.validation.alert-rules":
      "Session will expire in {minutes}:{seconds}. To continue the session click OK.",

    // Common Unit Labels
    "common.units.sq-ft": "sq. ft.",
    "common.units.sq-m": "sq. m.", // TODO
    "common.units.dollar": "$",
    "common.units.dollar-per-sq-ft": "${value}/sq. ft.",
    "common.units.dollar-per-hour": "$/hr",
    "common.units.dollar-per-day": "$/day",
    "common.units.dollar-per-month": "$/month",
    "common.units.num-per-sq-ft": "#/sq. ft.",
    "common.units.voltage": "V",
    "common.units.amperage": "A",
    "common.units.watt": "W",
    "common.units.watt-per-sq-ft": "W/sq. ft.",
    "common.units.percentage": "%",
    "common.units.acres": "acres",
    "common.units.dollar-per-acre": "$/acre",
    "common.units.feet": "ft.",
    "common.units.inches": "in.",
    "common.units.ft-in": "ft. in.",
    "common.units.years": "years",
    "common.units.years-short": "yrs",
    "common.units.months-short": "mo",
    "lang.formatted-unit.id": "{value, number}",

    // Common Button Labels
    "common.checkbox.toggle-all.select-all": "Select All",
    "common.button.update.label": "Update",
    "common.button.cancel.label": "Cancel",
    "common.button.edit.label": "Edit",
    "common.button.delete.label": "Delete",
    "common.button.save.label": "Save",
    "common.button.reset.label": "Reset",
    "common.button.next.label": "Next",
    "common.button.filter.label": "Filter",
    "common.button.yes.label": "Yes",
    "common.button.no.label": "No",
    "common.button.ok.label": "OK",
    "common.button.add.label": "Add",
    "common.button.transfer.label": "Transfer",
    "common.button.copy.label": "Copy",
    "common.button.add-user.label": "Add User",
    "common.button.transfer-buildings.label": "Transfer Buildings",
    "common.button.copy-buildings.label": "Copy Buildings",
    "common.button.create-user.label": "Create User",
    "common.button.add-buildings.label": "Add Buildings",
    "common.button.upload.label": "Upload",
    "common.button.close.label": "Close",
    "common.button.new.label": "New",
    "common.button.add.new.tour.label": "Add New Virtual Tour",
    "common.button.download.leads": "Download Leads",

    "virtual.tours.none": "No Virtual Tours to Show",
    "virtual.tours.suite": "Suite Virtual Tours",
    "virtual_tours.optional.placeholder": "(Optional)",

    // Login page
    "login.heading.greeting.label": "Get Started with CoRE.",
    "login.input.username.label": "Username",
    "login.input.password.label": "Password",
    "login.input.forgot-password.label": "Forgot Password",
    "login.btn.login.label": "Log In",
    "login.btn.login-mri-id.label": "Log in with MRI Client ID",
    "login.btn.login-no-mri-id.label": "Log in without MRI Client ID",
    "login.line.or.label": "Or",
    "login.heading.recover-password.label": "Recover Password",

    "login.forgot-password.form.field.username.label": "Username",
    "login.forgot-password.form.button.reset-password.label": "Reset Password",
    "login.forgot-password.success.title": "Email sent!",
    "login.forgot-password.success.content1":
      "An email with instructions on how to reset your password has been sent to",
    "login.forgot-password.success.content2":
      "Check your spam or junk folder if you don't see the email in your inbox.",
    "login.forgot-password.form.info.click.label": "Click",
    "login.forgot-password.form.info.here.label": "here",
    "login.forgot-password.form.info.login-redirect.label":
      "to go to the login page.",

    // Header page
    "header.search.placeholder": "Search...",
    "header.user-context-menu.settings.label": "Settings",
    "header.user-context-menu.help-support.label": "Help & Support",
    "header.user-context-menu.logout.label": "Logout",
    "header.language-menu.select-language.label": "Select Language",
    "header.search.archivetag": "Archived",

    // Left navigation
    "nav.icon.dashboard.helpertext": "Dashboard",
    "nav.icon.listings.helpertext": "Listings",
    "nav.icon.buildings.helpertext": "Buildings",
    "nav.icon.settings.helpertext": "Settings",

    // Print PDF
    "print.stacking-plan.filter-criteria.label": "Filter Criteria",
    "print.stacking-plan.suite-type.label": "Suite Type",
    "print.stacking-plan.suite-availability-type.label":
      "Suite Availability Type",
    "print.stacking-plan.area-range.label": "Area Range",
    "print.stacking-plan.profile-lease-expiry.label": "Profile - Lease Expiry",
    "print.stacking-plan.profile-subtenancies.label": "Profile - Subtenancies",
    "print.stacking-plan.tenant-rights.label": "Tenant Rights and Encumbrances",

    // Dashboard Page
    "dashboard.context-pane.header.page-title": "Dashboard",
    "dashboard.context-pane.tabs.marketing.label": "Marketing",
    "dashboard.context-pane.tabs.leasing.label": "Leasing",
    "dashboard.meta-header.description.engagement": "Engagement",
    "dashboard.meta-header.description": `Reports for ${numberWithPlaceholder(
      "totalBuildings",
      "{totalBuildings, plural, one {Building} other {Buildings}}"
    )} (Owned or Managed)`,
    "dashboard.meta-header.stats.area-affected.number": numberWithPlaceholder(
      "areaAffected"
    ),
    "dashboard.meta-header.stats.total-suites.number": numberWithPlaceholder(
      "totalSuites"
    ),
    "dashboard.meta-header.stats.new-suites.number": numberWithPlaceholder(
      "newSuites"
    ),
    "dashboard.meta-header.stats.new-leases.number": numberWithPlaceholder(
      "newLeases"
    ),
    "dashboard.meta-header.stats.deleted-suites.number": numberWithPlaceholder(
      "deletedSuite"
    ),
    "dashboard.meta-header.stats.new-leads.number": numberWithPlaceholder(
      "newLeads"
    ),
    "dashboard.meta-header.stats.tours.number": numberWithPlaceholder("tours"),
    "dashboard.meta-header.stats.loi.number": numberWithPlaceholder("loi"),
    "dashboard.meta-header.stats.won.number": numberWithPlaceholder("won"),
    "dashboard.meta-header.stats.lost.number": numberWithPlaceholder("lost"),
    "dashboard.meta-header.stats.qualified.number": numberWithPlaceholder(
      "qualified"
    ),

    "dashboard.meta-header.stats.area-affected.label":
      "Total Suite Area (sq. ft.)",
    "dashboard.meta-header.stats.suites-affected.label":
      "{totalSuites, plural, one {Suite} other {Suites}} Affected",
    "dashboard.meta-header.stats.new-suites.label":
      "New {newSuites, plural, one {Suite} other {Suites}}",
    "dashboard.meta-header.stats.new-leases.label":
      "New {newLeases, plural, one {Lease} other {Leases}}",
    "dashboard.meta-header.stats.deleted-suites.label":
      "Deleted {deletedSuites, plural, one {Suite} other {Suites}}",
    "dashboard.meta-header.stats.new-leads.label":
      "New {newLeads, plural, one {Lead} other {Leads}}",
    "dashboard.meta-header.stats.qualified.label": "Qualified",
    "dashboard.meta-header.stats.tours.label":
      "{tours, plural, one {Tour} other {Tours}}",
    "dashboard.meta-header.stats.loi.label": "LOI",
    "dashboard.meta-header.stats.won.label": "Won",
    "dashboard.meta-header.stats.lost.label": "Lost",
    "dashboard.meta-header.stats.broker-network": "Broker Network",
    "dashboard.meta-header.stats.conversions": "Total Marketing Conversions",
    "dashboard.meta-header.stats.total-page-views": "Total Pageviews",
    "dashboard.meta-header.stats.page-views-since": "since",
    "dashboard.meta-header.stats.conversions.tooltip":
      "Sum of Marketing Qualified Leads and Property Downloads",
    "dashboard.meta-header.stats.broker-network.tooltip":
      "Distinct individuals who entered their email address in the website",

    "dashboard.distribution-report.suite-area.number": "{area, number}",
    "dashboard.distribution-report.no-of-suites.number": "{suites, number}",
    "dashboard.distribution-report.total-area.number": "{totalArea, number}",
    "dashboard.distribution-report.total-suites.number":
      "{totalSuites, number}",
    "dashboard.distribution-report.total-suite-area.number":
      "{totalSuiteArea, number}",

    "dashboard.lease-expiry-profile.suite-area.number": "{area, number}",
    "dashboard.lease-expiry-profile.no-of-suites.number": "{suites, number}",
    "dashboard.lease-expiry-profile.total-suites.number":
      "{totalSuites, number}",
    "dashboard.lease-expiry-profile.total-suite-area.number":
      "{totalSuiteArea, number}",

    "dashboard.report.suite-distribution.header.label": "SUITE DISTRIBUTION",
    "dashboard.report.lease-expiry-profile.header.label":
      "LEASE EXPIRY PROFILE",
    "dashboard.report.lease-expiry-profile.space-type.label": "Type",
    "dashboard.report.lease-expiry-profile.status.label": "Status",
    "dashboard.report.lease-expiry-profile.suite-area.label":
      "Suite Area (sq. ft.)",
    "dashboard.report.lease-expiry-profile.no-of-suites.label": "No. of Suites",
    "dashboard.report.lease-expiry-profile.percentage-total.label": "% Total",
    "dashboard.report.lease-expiry-profile.report-table.label": "Report Total",
    "dashboard.report.lease-expiry-profile.total-rentable-area.label":
      "Total Rentable Area",
    "dashboard.report.insights.header.label": "PAGE VIEWS",
    "dashboard.report.engagement.header.label": "MARKETING CONVERSIONS",

    "dashboard.filter-panel.panel-header.building.label": "Portfolio",
    "dashboard.filter-panel.section-header.fund.label": "Ownership Group",
    "dashboard.filter-panel.section-header.type.label": "Type",
    "dashboard.filter-panel.panel-header.location.label": "Location",
    "dashboard.filter-panel.section-header.province-state.label":
      "Province/State",
    "dashboard.filter-panel.section-header.region.label": "Region",
    "dashboard.filter-panel.section-header.sub-region.label": "Sub-Region",
    "dashboard.filter-panel.section-header.groups.label": "Groups",
    "dashboard.filter-panel.section-header.city.label": "City",
    "dashboard.filter-panel.section-header.company.label":
      "Property Management",
    "dashboard.filter-panel.placeholder.company-select.label": "Select PM",
    "dashboard.filter-panel.placeholder.fund-select.label":
      "Select Ownership Group",
    "dashboard.filter-panel.placeholder.province-state-select.label":
      "Select Province/State",
    "dashboard.filter-panel.placeholder.region-select.label": "Select Region",
    "dashboard.filter-panel.placeholder.sub-region-select.label":
      "Select Sub-Region",
    "dashboard.filter-panel.placeholder.group-select.label": "Select Group",
    "dashboard.redirect-banner.label": "New Marketing dashboard now available!",
    "dashboard.redirect-banner.description.label":
      "Get real time analytics on your marketing qualified leads, top listings, traffic and more.",
    "dashboard.redirect-banner.btn.label": "Explore the new version",

    // Suite Listing View Page
    "listings.context-pane.header.page-title": "Listings",
    "listings.vs.label": "vs",

    "listings.meta-header.stats.published-area.label": "Published Suite Area",
    "listings.meta-header.stats.non-published-area.label":
      "Non-Published Suite Area",
    "listings.meta-header.stats.total-suite-area.label": "Total Suite Area",
    "listings.meta-header.stats.published-area": numberWithPlaceholder(
      "publishedArea",
      SQUARE_FEET_ENGLISH
    ),
    "listings.meta-header.stats.non-published-area": numberWithPlaceholder(
      "nonPublishedArea",
      SQUARE_FEET_ENGLISH
    ),
    "listings.meta-header.stats.total-suite-area": numberWithPlaceholder(
      "totalSuiteArea",
      SQUARE_FEET_ENGLISH
    ),
    "listings.meta-header.stats.published-suites.number-label": numberWithPlaceholder(
      "publishedSuites",
      "{publishedSuites, plural, one {suite} other {suites}}"
    ),
    "listings.meta-header.stats.non-published-suites.number-label": numberWithPlaceholder(
      "nonPublishedSuites",
      "{nonPublishedSuites, plural, one {suite} other {suites}}"
    ),
    "listings.meta-header.stats.total-suites.number-label": numberWithPlaceholder(
      "totalSuites",
      "{totalSuites, plural, one {suite} other {suites}}"
    ),
    "listings.suite-list-view.table.header.suite-type": "Suite Type",
    "listings.suite-list-view.table.header.suite": "Suite",
    "listings.suite-list-view.table.header.type": "Type",
    "listings.suite-list-view.table.header.building": "Building",
    "listings.suite-list-view.table.header.available-area": "Available Area",
    "listings.suite-list-view.table.header.contig-div": "(Contig. | Divisible)",
    "listings.suite-list-view.table.header.marketing-score": "Marketing Score",
    "listings.suite-list-view.table.header.online-activity": "Online Activity",
    "listings.suite-list-view.table.header.leads": "Leads",
    "listings.suite-list-view.table.header.dom": "Days on Market",
    "listings.suite-list-view.table.header.status": "Status",
    "listings.suite-list-view.table.available-area.number":
      "{availableArea, number}",
    "listings.suite-list-view.table.available-area-subtext.number":
      "({contiguousArea, number} | {minimumDivisibleArea, number})",

    "listings.filter-panel.section-header.suite-type.label": "Suite Type",
    "listings.filter-panel.section-header.leasing-contact.label":
      "Leasing Contact",
    "listings.filter-panel.section-header.city.label": "City",
    "listings.filter-panel.section-header.size.label": "Size (sq.ft.)",

    // Buildings List View Page
    "buildings.context-pane.title.buildings.label": "Buildings",
    "buildings.context-pane.button.new-building.label": "New Building",
    "buildings.context-pane.tabs.all-buildings.label": "All Buildings",
    "buildings.context-pane.tabs.promoted.label": "Promoted",

    "buildings.meta-header.stats.no-of-buildings.label": "No. of Buildings",
    "buildings.meta-header.stats.promoted-buildings.label":
      "Promoted Buildings",
    "buildings.meta-header.stats.no-of-groups.label": "No. of Groups",
    "buildings.meta-header.stats.types-of-buildings.label":
      "Types of Buildings",
    "buildings.meta-header.stats.suite-count.number-label": numberWithPlaceholder(
      "suiteCount",
      "{suiteCount, plural, one {suite} other {suites}}"
    ),
    "buildings.meta-header.stats.suites-included.number-label": numberWithPlaceholder(
      "promotedSuitesCount",
      "{promotedSuitesCount, plural, one {suite} other {suites}} included"
    ),
    "buildings.meta-header.stats.grouped-buildings-included.number-label": numberWithPlaceholder(
      "groupedBuildingsCount",
      "{groupedBuildingsCount, plural, one {building} other {buildings}} included"
    ),
    "buildings.meta-header.stats.offices.label":
      "{officeBuildingCount, plural, one {Office} other {Office}}",
    "buildings.meta-header.stats.industrial.label": "Industrial",
    "buildings.meta-header.stats.retail.label": "Retail",
    "buildings.meta-header.stats.residential.label": "Residential",
    "buildings.meta-header.stats.land.label": "Land",
    "buildings.meta-header.controls.sort-by.label": "Sort By",
    "buildings.meta-header.controls.list-view.label": "List View",
    "buildings.meta-header.controls.grid-view.label": "Grid View",

    "buildings.list-view.table.header.ranking": "Ranking",
    "buildings.list-view.table.header.building": "Building",
    "buildings.list-view.table.header.address": "Address",
    "buildings.list-view.table.header.no-of-floors": "No. of Floors",
    "buildings.list-view.table.header.type": "Type",
    "buildings.list-view.table.header.rentable-area": "Rentable Area",
    "buildings.list-view.table.header.listed-area": "Listed Area",
    "buildings.list-view.table.header.published": "Published",

    "buildings.list-view.table.col.area.number": "{area, number} sq. ft.",
    "buildings.list-view.table.col.available-area.number":
      "{availableArea, number} sq. ft.",
    "buildings.list-view.table.col.suites.label":
      "{availableSuites, number} {availableSuites, plural, one {suite} other {suites}}",
    "buildings.published-toggle.private.confirmation-message":
      "Are you sure you want to make this building private ?",
    "buildings.published-toggle.public.confirmation-message":
      "Are you sure you want to make this building public ?",

    "buildings.grid-view.meta.floor-count.number":
      "{floorCount, number} {floorCount, plural, one {Floor} other {Floors}}",
    "buildings.grid-view.meta.area.number-label": "{area, number} sq.ft.",
    "buildings.grid-view.meta.available-area.number-label":
      "{availableArea, number} sq.ft. available",
    "buildings.grid-view.meta.total-suites.label":
      "{totalSuites, number} {totalSuites, plural, one {suite} other {suites}}",
    "buildings.grid-view.meta.available-suites.label":
      "{availableSuites, number} {availableSuites, plural, one {suite} other {suites}}",
    "buildings.grid-view.meta.available-suite-min-area.number-label":
      "{availableSuiteMinArea, number} sq.ft.",
    "buildings.grid-view.meta.available-suite-max-area.number-label":
      "{availableSuiteMaxArea, number} sq.ft.",

    "buildings.filter-panel.panel-header.building.label": "Building",

    "buildings.filter-panel.panel-header.building-type.label": "Building Type",
    "buildings.filter-panel.panel-header.city.label": "City",
    "buildings.filter-panel.panel-header.size.label": "Size(sq.ft.)",
    "buildings.filter-panel.panel-header.visibility.label": "Visibility",
    "buildings.filter-panel.panel-header.favourites.label": "Favourites",
    "buildings.filter-panel.panel-header.archived.label": "Archived",
    "buildings.filter-panel.section-header.fund.label": "Ownership Group",
    "buildings.filter-panel.section-header.group.label": "Groups",
    "buildings.filter-panel.section-header.company.label":
      "Property Management",
    "buildings.filter-panel.section-header.type.label": "Type",
    "buildings.filter-panel.placeholder.fund-select.label":
      "Select Ownership Group",
    "buildings.filter-panel.placeholder.group-select.label": "Select Group",
    "buildings.filter-panel.placeholder.company-select.label": "Select PM",

    "buildings.building-menu.view-building.label": "View Building",
    "buildings.building-menu.group-building.label": "Group Building",
    "buildings.building-menu.unpublish.label": "Unpublish",
    "buildings.building-menu.publish.label": "Publish",
    "buildings.building-menu.unpromote.label": "Unpromote",
    "buildings.building-menu.promote.label": "Promote",
    "buildings.building-menu.unarchive.label": "Unarchive",
    "buildings.building-menu.archive.label": "Archive",
    "buildings.building-menu.delete.label": "Delete",
    "buildings.delete-building.confirmation-msg":
      "Are you sure you want to delete the building -",
    "buildings.delete-group.confirmation-msg":
      "Are you sure you want to remove the building group? -",

    "building.context-pane.tabs.stacking-plan.label": "Stacking Plan",
    "building.context-pane.tabs.site-map.label": "Site Map",
    "building.context-pane.tabs.rent-roll.label": "Rent Roll",
    "building.context-pane.tabs.reports.label": "Reports",
    "building.context-pane.tabs.information.label": "Information",
    "building.context-pane.tabs.specifications.label": "Specifications",
    "building.context-pane.tabs.media.label": "Media",
    "building.context-pane.tabs.contacts.label": "Contacts",
    "building.context-pane.tabs.leases.label": "Leases",

    "building.context-pane.print-stackng-plan.progress-message":
      "Generating PDF. This may take a few seconds.",

    "building.group-building.popup.title.label": "Group Building",
    "building.group-building.popup.no-group-info":
      "No such group exists at the moment. Create one?",
    "building.group-building.popup.no-groups-info":
      "No groups exist at the moment. Create one?",
    "building.group-building.popup.usage-directions":
      "Add building to an existing group or create a new group",
    "building.group-building.popup.create-group-usage-directions":
      "Enter your group name",
    "building.group-building.popup.button.add-to-selected-group.label":
      "Add To Selected Group",
    "building.group-building.popup.button.create-group.label": "Create Group",
    "building.group-building.popup.button.submit.label": "Add Group",
    "building.group-building.popup.button.go-back.label": "Go Back",

    // Buildings - Promoted Buildings View
    "buildings.promoted.buildings.page.title": "About Promoted Buildings",
    "buildings.promoted.buildings.page.info":
      "Promoted buildings are based on their ranking. The higher the ranking, the more promoted the building is. Rerank buildings by simply clicking and dragging buildings up and down the ranking.",
    "buildings.promoted.buildings.table.no-buildings.promoted.info":
      "No buildings promoted",
    "buildings.promoted.buildings.table.dnd.drop.placeholder": "Drop here",

    // Buildings - Add New Building Wizard
    "building.add-new-wizard.header.page-title": "Create New Building",
    "building.add-new-wizard.steps.building-type.label": "Building Type",
    "building.add-new-wizard.steps.information.label": "Information",
    "building.add-new-wizard.steps.building-type.select-type.instruction":
      "Select Type of Building",
    "building.add-new-wizard.steps.information.form.building-name.label":
      "Building Name",
    "building.add-new-wizard.steps.information.form.no-of-floors.label":
      "Number of Floors",
    "building.add-new-wizard.steps.information.form.above.label": "ABOVE",
    "building.add-new-wizard.steps.information.form.ground.label": "Ground",
    "building.add-new-wizard.steps.information.form.below.label": "BELOW",
    "building.add-new-wizard.steps.information.form.own-building.label":
      "Do you own this building ?",
    "building.add-new-wizard.steps.information.form.manage-building.label":
      "Do you manage this building ?",
    "building.add-new-wizard.steps.information.form.manage-building.own.label":
      "Own",
    "building.add-new-wizard.steps.information.form.manage-building.manage.label":
      "Manage",
    "building.add-new-wizard.steps.information.form.address.label": "Address",
    "building.add-new-wizard.steps.information.form.city.label": "City",
    "building.add-new-wizard.steps.information.form.region.label": "Region",
    "building.add-new-wizard.steps.information.form.neighbourhood.label":
      "Neighbourhood",
    "building.add-new-wizard.steps.information.form.sub-region.label":
      "Sub Region",
    "building.add-new-wizard.steps.information.form.province.label": "Province",
    "building.add-new-wizard.steps.information.form.country.label": "Country",
    "building.add-new-wizard.steps.information.form.postal.label":
      "Postal/Zip Code",
    "building.add-new-wizard.steps.information.form.localtion.label":
      "Location",
    "building.add-new-wizard.steps.information.form.localtion.instructions":
      "Select an area on the map by searching for it. You can override the specifics of the searched address in the form below.",
    "building.add-new-wizard.steps.information.form.button.create-building.label":
      "Create Building",

    // Buildings - Leases List View Page
    "leases.filters.datepicker.from.label": "From",
    "leases.filters.datepicker.to.label": "To",
    "leases.filters.datepicker.input.placeholder": "Select date...",
    "leases.filters.suite-selector.input.placeholder": "Select...",
    "leases.header.new-lease.button.label": "New Lease",
    "leases.header.expired-leases.button.label": "Show Expired",
    "leases.list-view.table.header.suite": "Suite",
    "leases.list-view.table.header.start-date": "Start Date",
    "leases.list-view.table.header.end-date": "End Date",
    "leases.list-view.table.header.tenant-name": "Tenant Name",
    "leases.list-view.table.header.availability": "Availability",
    "leases.list-view.table.header.area": "Area",
    "leases.list-view.table.header.sf": "(sf)",
    "leases.list-view.table.header.rent": "Rent",
    "leases.list-view.table.header.$sf": "($/sf)",
    "leases.list-view.table.header.free-rent": "Free Rent",
    "leases.list-view.table.header.mo": "(mo)",
    "leases.list-view.table.header.ti": "TI",
    "leases.list-view.table.header.lw": "LW",
    "leases.list-view.table.header.ext-lc": "EXT LC",
    "leases.list-view.table.header.mgmt-lc": "MGMT LC",
    "leases.list-view.table.header.incentive-total": "Incentive Total",
    "leases.list-view.table.area.number": "{area, number}",
    "leases.list-view.table.availability-option.month-to-month.label":
      "Month to Month",
    "leases.list-view.table.availability-option.leased.label": "Leased",
    "leases.list-view.table.availability-option.pending.label": "Pending",
    "leases.filters.date-filter.range-validation.message":
      "'From' date can't be greater than the 'To' date!",
    "lease.edit-lease.section.tenant-rights.title":
      "Tenant Rights and Encumbrances",
    "leases.add-new-lease.dialog.title": "Create New Lease",
    "leases.add-new-lease.dialog.suite.label": "Suite",
    "leases.add-new-lease.dialog.suite.tenant-name": "Tenant Name",
    "leases.add-new-lease.dialog.suite.start_date": "Start Date",
    "leases.add-new-lease.dialog.suite.end_date": "End Date",
    "leases.add-new-lease.dialog.suite.is_mo_to_mo": "Availability",
    "leases.add-new-lease.dialog.suite.area": "Leased Area",
    "leases.add-new-lease.dialog.suite.rent": "Rent",
    "leases.add-new-lease.dialog.suite.months_free_rent": "Free Rent",
    "leases.add-new-lease.dialog.suite.tenant_inducement": "TI",
    "leases.add-new-lease.dialog.suite.landlord_work_cost": "LW",
    "leases.add-new-lease.dialog.suite.external_leasing_cost": "EXT LC",
    "leases.add-new-lease.dialog.suite.internal_leasing_cost": "MGMT LC",
    "leases.add-new-lease.dialog.suite.incentive": "Incentive Total",
    "leases.add-new-lease.dialog.tenant-rights":
      "Tenant Rights and Encumbrances",
    "leases.add-new-lease.dialog.create-success-msg":
      "Lease created successfully!",
    "leases.delete-lease.confirmation-dialog.title": "Confirmation",
    "leases.delete-lease.confirmation-msg":
      "By clicking on 'Yes', you are confirming that you want to delete lease for",

    // Buildings - Stacking Plan View Page
    "floorplan.menu.upload-image-text": "+ Upload Floor Plan Image",
    "floorplan.menu.download-svg-text": "+ Download SVG",
    "floorplan.menu.upload-svg-text": "+ Upload SVG",
    "floorplan.floormap.no-floorplan": "No floorplan uploaded for this floor!",
    "stackingplan.floor.vacant-floor.title.label": "Vacant Floor",
    "stackingplan.floor.vacant-floor.floor-identifier.placeholder":
      "Click to enter floor identifier",
    "stackingplan.floor.vacant-floor.floor-identifier.helptext":
      "Enter floor identifier",
    "stackingplan.floor.vacant-floor.floor-identifier.helptext-small":
      "Floor name",
    "stackingplan.floor.vacant-floor.area.placeholder": "Click to enter area",
    "stackingplan.floor.vacant-floor.area.helptext": "Enter area",
    "stackingplan.floor.vacant-floor.area.helptext-small": "Area",
    "stackingplan.floor.vacant-floor.avl-area.label": "Avl: Unknown",
    "stackingplan.floor.info.suite-count.number-label":
      "{suiteCount, number} {suiteCount, plural, one {Suite is} other {Suites are} } on this floor",
    "stackingplan.floor.info.expand-helptext.label":
      "Click to expand and see suites",
    "stackingplan.floor.floorplan.link.view-floor-plan": "View Floor Plan",
    "stackingplan.floor.floorplan.link.create-floor-plan": "Create Floor Plan",
    "stackingplan.floor.floorplan.link.no-floor-plan": "No Floor Plan",

    "stackingplan.floorplan.tooltip.commencement.label": "Commencement: ",
    "stackingplan.floorplan.tooltip.expiry.label": "Expiry: ",
    "stackingplan.floorplan.tooltip.base-rent.label": "Base Rent: $",

    "stackingplan.floor.floor-menu.add-suite": "Add Suite",
    "stackingplan.floor.floor-menu.add-floor-above": "Add New Floor Above",
    "stackingplan.floor.floor-menu.add-floor-below": "Add New Floor Below",
    "stackingplan.floor.floor-menu.delete-floor": "Delete Floor",
    "stackingplan.floor.delete-floor.confirmation-msg":
      "Are you sure you want to delete this floor ?",

    "stackingplan.suite.meta.suite-area.number-label":
      "{suiteArea, number} sq.ft.",
    "stackingplan.suite.meta.exp.label": "Exp",
    "stackingplan.suite.meta.avl-date.label": "Avl",
    "stackingplan.suite.meta.lease.start-date.label": "Start Date",
    "stackingplan.suite.meta.marketing-score.label": "Marketing Score",
    "stackingplan.suite.meta.leased.label": "Leased",
    "stackingplan.suite.meta.committed.label": "Committed",
    "stackingplan.suite.meta.vacant.label": "Vacant",
    "stackingplan.suite.meta.pending.label": "Pending",
    "stackingplan.suite.meta.archived.label": "Archived",
    "stackingplan.suite.meta.tenant-name.undisclosed.label": "Undisclosed",
    "stackingplan.suite.meta.avl-date.unknown": "Unknown",

    "stackingplan.suite.suite-menu.split-suite": "Split Suite",
    "stackingplan.suite.suite-menu.move-left": "Move Left",
    "stackingplan.suite.suite-menu.move-right": "Move Right",
    "stackingplan.suite.suite-menu.archive-suite": "Archive Suite",
    "stackingplan.suite.suite-menu.unarchive-suite": "Unarchive Suite",
    "stackingplan.suite.suite-menu.delete-suite": "Delete Suite",
    "stackingplan.suite.suite-menu.add-suite-left": "Add New Suite to Left",
    "stackingplan.suite.suite-menu.add-suite-right": "Add New Suite to Right",
    "stackingplan.suite.suite-menu.add-floor-above": "Add New Floor Above",
    "stackingplan.suite.suite-menu.add-floor-below": "Add New Floor Below",
    "stackingplan.suite.suite-menu.delete-floor": "Delete Floor",

    "stackingplan.suite.dnd.drop-here": "Drop here",
    "stackingplan.floor.suite.delete-suite.confirmation-msg":
      "Are you sure you want to delete this suite ?",

    "stackingplan.filter-panel.header.suite-filters.label": "Suite Filters",
    "stackingplan.filter-panel.general-filters.label": "General Filters",
    "stackingplan.filter-panel.subtenancies.label": "Subtenancies",
    "stackingplan.filter-panel.marketed.label": "Marketed",
    "stackingplan.filter-panel.suite-search-filter.helpertext":
      "Search tenant or suite",
    "stackingplan.filter-panel.suite-type.helpertext": "Select suite type",
    "stackingplan.filter-panel.suite-type.label": "Suite Type",
    "stackingplan.filter-panel.availability.label": "Availability",
    "stackingplan.filter-panel.availability.helpertext":
      "Select availability type",
    "stackingplan.filter-panel.visibility.helpertext": "Select visibility type",
    "stackingplan.filter-panel.size.label": "Size (sq.ft.)",
    "stackingplan.filter-panel.profile.label": "Profile",
    "stackingplan.filter-panel.office-space.label": "Office Space",
    "stackingplan.filter-panel.basic-gross-rent.label":
      "Basic/Gross Rent ($/mo)",
    "stackingplan.filter-panel.tenant-rights.label":
      "Tenant Rights and Encumbrances",
    "stackingplan.filter-panel.measurement-standards.label":
      "Measurement Standards",
    "stackingplan.filter-panel.retail-space.label": "Retail Space",

    "stackingplan.filter-panel.select-tabs.lease-expiry.label": "Lease Expiry",
    "stackingplan.filter-panel.select-tabs.tenant-rights.label":
      "Tenant Rights and Encumbrances",
    "stackingplan.filter-panel.select-tabs.subtenancies.label": "Subtenancies",
    "stackingplan.filter-panel.select-tabs.marketing.label": "Marketing",
    "stackingplan.filter-panel.select-tabs.committed-space.label":
      "Committed Space",
    "stackingplan.filter-panel.select-tabs.lease-expiry.sq-footage.number-label":
      "{squareFootage, number} sq.ft.",
    "stackingplan.filter-panel.select-tabs.lease-expiry.mo-to-mo.label":
      "Mo-to-Mo",
    "stackingplan.filter-panel.select-tabs.lease-expiry.vacant.label": "Vacant",

    "stackingplan.filter-panel.suite-details.header.tabs.leases.label":
      "Leases",
    "stackingplan.filter-panel.suite-details.header.tabs.information.label":
      "Information",
    "stackingplan.filter-panel.suite-details.header.tabs.competitive-listing.label":
      "Competitive Listing",
    "stackingplan.filter-panel.suite-details.header.tabs.virtual-tour.label":
      "Virtual Tour",
    "buildingspec.form-section.field.label.tour_name": "Virtual Tour Name",
    "buildingspec.form-section.field.label.tour_url": "Virtual Tour URL",

    "virtual_tours.private-toggle.confirmation-msg":
      "Are you sure you want to make this Virtual Tour private?",
    "virtual_tours.public-toggle.confirmation-msg":
      "Are you sure you want to make this Virtual Tour public?",
    "virtual_tours.private.label": "Private",
    "virtual_tours.public.label": "Public",

    "stackingplan.filter-panel.suite-details.header.no-media.label": "No Media",
    "stackingplan.filter-panel.suite-details.header.add-suite-media.tooltip":
      "Click to add media to suite {suiteNumber}.",

    "stackingplan.filter-panel.suite-details.information.tabs.marketing-attributes.label":
      "Marketing Attributes",
    "stackingplan.filter-panel.suite-details.information.tabs.construction.label":
      "Construction",
    "stackingplan.filter-panel.suite-details.information.tabs.technical.label":
      "Technical",
    "stackingplan.filter-panel.suite-details.information.tabs.parking.label":
      "Parking",
    "stackingplan.filter-panel.suite-details.information.tabs.other-attributes.label":
      "Other Attributes",

    "stackingplan.filter-panel.suite-details.button.add.label": "Add",
    "stackingplan.filter-panel.suite-details.button.edit.label": "Edit",
    "stackingplan.filter-panel.suite-details.lease.tenant-name": "Tenant Name",
    "stackingplan.filter-panel.suite-details.lease.availability":
      "Availability",
    "stackingplan.filter-panel.suite-details.lease.start-date": "Start Date",
    "stackingplan.filter-panel.suite-details.lease.end-date": "End Date",
    "stackingplan.filter-panel.suite-details.lease.expiry-date": "Expiry Date",
    "stackingplan.filter-panel.suite-details.lease.area": "Area (sf)",
    "stackingplan.filter-panel.suite-details.lease.no-data.label": "No Leases",
    "stackingplan.filter-panel.suite-details.lease.leased.label": "Leased",
    "stackingplan.filter-panel.suite-details.lease.leased-area.number-label":
      "{area, number} sq.ft.",
    "stackingplan.filter-panel.suite-details.lease.rent": "Rent ($/sf)",
    "stackingplan.filter-panel.suite-details.lease.incentive": "Incentive",
    "stackingplan.filter-panel.suite-details.lease.external_leasing_cost":
      "External Leasing Cost",
    "stackingplan.filter-panel.suite-details.lease.internal_leasing_cost":
      "Internal Leasing Cost",
    "stackingplan.filter-panel.suite-details.lease.landlord_work_cost":
      "Landlord Work Cost",
    "stackingplan.filter-panel.suite-details.lease.tenant_inducement":
      "Tenant Inducement",
    "stackingplan.filter-panel.suite-details.lease.tenant-rights":
      "Tenant Rights and Encumbrances",
    "stackingplan.filter-panel.suite-details.information.broker-update.tooltip":
      "Note: modifying this field will trigger a broker update.",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.panel.marketing-attributes.label":
      "Marketing Attributes",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.panel.other-attributes.label":
      "Other Attributes",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.private.label":
      "Private",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.public.label":
      "Public",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.private-toggle.confirmation-msg":
      "Are you sure you want to make this suite private ?",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.public-toggle.confirmation-msg":
      "Are you sure you want to make this suite public ?",
    "stackingplan.filter-panel.suite-details.header.tabs.broker_update.label":
      "Include in broker updates?",
    "stackingplan.filter-panel.suite-details.information.just-leased":
      "Just Leased",
    "stackingplan.filter-panel.suite-details.information.just-leased.tooltip":
      'Display a "Just Leased" label until the date populated. Note: modifying this field will trigger a broker update.',
    "stackingplan.filter-panel.suite-details.information.new-suite":
      "New Suite",
    "stackingplan.filter-panel.suite-details.information.model-suite":
      "Model Suite",
    "stackingplan.filter-panel.suite-details.information.promote-suite":
      "Promoted Suite",
    "stackingplan.filter-panel.suite-details.information.import-id":
      "Import Id",
    "stackingplan.filter-panel.suite-details.information.marketing-area":
      "Marketing Area",
    "stackingplan.filter-panel.suite-details.information.max-contiguous":
      "Max. Contiguous",
    "stackingplan.filter-panel.suite-details.information.min-divisible":
      "Min. Divisible",
    "stackingplan.filter-panel.suite-details.information.availability-date":
      "Availability Date",
    "stackingplan.filter-panel.suite-details.information.notice-period":
      "Notice Period",
    "stackingplan.filter-panel.suite-details.information.rent": "Rent",
    "stackingplan.filter-panel.suite-details.information.additional-total":
      "Additional Total",
    "stackingplan.filter-panel.suite-details.information.suite-notes":
      "Suite Notes",
    "stackingplan.filter-panel.suite-details.information.suite_identifier":
      "Suite Name",
    "stackingplan.filter-panel.suite-details.information._type": "Suite Type",
    "stackingplan.filter-panel.suite-details.information.condition":
      "Suite Condition",
    "stackingplan.filter-panel.suite-details.information.measured_area":
      "Measured Area",
    "stackingplan.filter-panel.suite-details.information.boma_ansi":
      "BOMA/ANSI",

    // Buildings - Stacking Plan View Page - Suite Residential Extra Fields
    "stackingplan.filter-panel.suite-details.information.suiteresidential.residential_suite_type":
      "Residential Suite Type",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.residential_building_type":
      "Residential Building Type",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.washrooms":
      "Washrooms",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.rooms":
      "Rooms",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.appliances":
      "Appliances",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.cooling":
      "Cooling",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.heating":
      "Heating",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.high_speed_internet":
      "High Speed Internet",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.deposit":
      "Deposit",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.utilities_included":
      "Utilities Included",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.kitchen_area":
      "Kitchen Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.dining_room_area":
      "Dining Room Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.living_room_area":
      "Living Room Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.master_bedroom_area":
      "Master Bedroom Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.bedroom_two_area":
      "Bedroom Two Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.bedroom_three_area":
      "Bedroom Three Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.bedroom_four_area":
      "Bedroom Four Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.master_bathroom_area":
      "Master Bathroom Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.bathroom_two_area":
      "Bathroom Two Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.den_area":
      "Den Area",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.balcony_patio":
      "Balcony Patio",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.storage_locker":
      "Storage Locker",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.parking_spot":
      "Parking Spot",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.furnished":
      "Furnished",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.hardwood_floors":
      "Hardwood Floors",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.fireplace":
      "Fireplace",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.washer_dryer_hookup":
      "Washer Dryer Hookup",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.cable_tv_ready":
      "Cable Tv Ready",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.satellite_tv_ready":
      "Satellite Tv Ready",
    "stackingplan.filter-panel.suite-details.information.placeholder":
      "Click to enter expiry date...",
    // Buildings - Stacking Plan View Page - Suite Industrial Extra Fields
    "stackingplan.filter-panel.suite-details.suiteindustrial.office_space":
      "Office Space",
    "stackingplan.filter-panel.suite-details.suiteindustrial.warehouse_space":
      "Warehouse Space",
    "stackingplan.filter-panel.suite-details.suiteindustrial.clear_height":
      "Clear Height",
    "stackingplan.filter-panel.suite-details.suiteindustrial.clear_height_notes":
      "Clear Height Notes",
    "stackingplan.filter-panel.suite-details.suiteindustrial.HVAC_notes":
      "HVAC Notes",
    "stackingplan.filter-panel.suite-details.suiteindustrial.lighting_notes":
      "Lighting Notes",
    "stackingplan.filter-panel.suite-details.suiteindustrial.available_voltage":
      "Available Voltage",
    "stackingplan.filter-panel.suite-details.suiteindustrial.available_amperage":
      "Available Amperage",
    "stackingplan.filter-panel.suite-details.suiteindustrial.electrical_notes":
      "Electrical Notes",
    "stackingplan.filter-panel.suite-details.suiteindustrial.sprinkler_system_notes":
      "Sprinkler System Notes",
    "stackingplan.filter-panel.suite-details.suiteindustrial.shipping_doors_drive":
      "Drive-in Doors",
    "stackingplan.filter-panel.suite-details.suiteindustrial.shipping_doors_dock":
      "Dock-high Doors",
    "stackingplan.filter-panel.suite-details.suiteindustrial.shipping_doors_notes":
      "Shipping Doors Notes",
    "stackingplan.filter-panel.suite-details.suiteindustrial.bay_width":
      "Bay Width",
    "stackingplan.filter-panel.suite-details.suiteindustrial.bay_depth":
      "Bay Depth",
    "stackingplan.filter-panel.suite-details.suiteindustrial.bay_notes":
      "Bay Notes",
    "stackingplan.filter-panel.suite-details.suiteindustrial.slab_notes":
      "Slab Notes",
    "stackingplan.filter-panel.suite-details.suiteindustrial.surface_stalls":
      "Surface Stalls",
    "stackingplan.filter-panel.suite-details.suiteindustrial.truck_trailer_parking":
      "Trailer Parking",
    "stackingplan.filter-panel.suite-details.suiteindustrial.surface_stall_ratio":
      "Surface Stall Ratio",
    "stackingplan.filter-panel.suite-details.suiteindustrial.parking_notes":
      "Parking Notes",

    // Buildings - Rent Roll View Page
    "rentroll.meta-header.stats.total-rentable-area.label":
      "Total Rentable Area",
    "rentroll.meta-header.stats.total-available-area.label":
      "Total Vacant Area",
    "rentroll.meta-header.stats.total-not-available-area.label":
      "Total Not Available Area",
    "rentroll.meta-header.stats.expires-less-1-year.label": "Expires < 1 Year",
    "rentroll.meta-header.stats.mo-to-mo.label": "Mo-to-Mo",
    "rentroll.meta-header.stats.committed.label": "Committed",

    "rentroll.meta-header.stats.total-rentable-area":
      "{totalRentableArea, number} sq.ft.",
    "rentroll.meta-header.stats.total-available-area":
      "{totalAvailableArea, number} sq.ft.",
    "rentroll.meta-header.stats.total-not-available-area":
      "{totalNotAvailableArea, number} sq.ft.",
    "rentroll.meta-header.stats.total-less-1-year":
      "{totalExpiresInLessThanOneYearArea, number} sq.ft.",
    "rentroll.meta-header.stats.total-mo-to-mo-area":
      "{totalMonthToMonthArea, number} sq.ft.",
    "rentroll.meta-header.stats.total-committed-area":
      "{totalCommittedArea, number} sq.ft.",

    "rentroll.meta-header.stats.total-rentable-count.number-label":
      "{totalRentableCount, number} {totalRentableCount, plural, one {suite} other {suites}} (100%)",
    "rentroll.meta-header.stats.total-available-count.number-label":
      "{totalAvailableCount, number} {totalAvailableCount, plural, one {suite} other {suites}}",
    "rentroll.meta-header.stats.total-not-available-count.number-label":
      "{totalNotAvailableCount, number} {totalNotAvailableCount, plural, one {suite} other {suites}}",
    "rentroll.meta-header.stats.total-expires-less-1-year.number-label":
      "{totalExpiresInLessThanOneYearCount, number} {totalExpiresInLessThanOneYearCount, plural, one {suite} other {suites}}",
    "rentroll.meta-header.stats.total-mo-to-mo-count.number-label":
      "{totalMonthToMonthCount, number} {totalMonthToMonthCount, plural, one {suite} other {suites}}",
    "rentroll.meta-header.stats.total-committed-count.number-label":
      "{totalCommittedCount, number} {totalCommittedCount, plural, one {suite} other {suites}}",

    "rentroll.filter-panel.section-header.size.label": "Size (sq.ft.)",
    "rentroll.filter-panel.section-header.visibility.label": "Visibility",

    "rentroll.bucket-list.header.suite.label": "Suite",
    "rentroll.bucket-list.header.floor.label": "Floor",
    "rentroll.bucket-list.header.type.label": "Type",
    "rentroll.bucket-list.header.tenant.label": "Tenant",
    "rentroll.bucket-list.header.area.label": "Area (sq.ft.)",
    "rentroll.bucket-list.header.rent.label": "Rent ($psf)",
    "rentroll.bucket-list.header.from-date.label": "From Date",
    "rentroll.bucket-list.header.to-date.label": "To Date",

    "rentroll.bucket-list.bucket.header.square-footage.number-label":
      "{squareFootage, number} sq.ft.",
    "rentroll.bucket-list.bucket.suit-area.number-label":
      "{suiteArea, number} sq.ft.",
    "rentroll.bucket-list.bucket.lease-rent.units-label":
      "${leaseRent, number} psf.",
    "rentroll.bucket-list.bucket.available-immediately.label":
      "Available Immediately",
    "rentroll.bucket-list.bucket.no-leases-msg.label":
      "There are no leases expiring in {bucketName}",
    "rentroll.bucket-list.bucket.vacant.no-leases-msg.label":
      "No vacant suites",
    "rentroll.bucket-list.bucket.mo-to-mo.no-leases-msg.label":
      "No month to month leases",

    // Buildings - Building Reports
    "buildingreports.activity-feed-panel.title.label": "ACTIVITY FEED",
    "buildingreports.occupancy-report.title.label": "OCCUPANCY",
    "buildingreports.occupancy-report.graph.plot.value":
      "{vacantArea, number} sq.ft. ({vacantPercentage}%)",
    "buildingreports.occupancy-report.legend.occupied": "OCCUPIED",
    "buildingreports.occupancy-report.legend.vacant": "VACANT",
    "buildingreports.occupancy-report.stats-table.floor.label": "Floor",
    "buildingreports.occupancy-report.stats-table.total-suites.label":
      "Total No. of Suites",
    "buildingreports.occupancy-report.stats-table.no-vacant.label":
      "No. of Vacant",
    "buildingreports.occupancy-report.stats-table.prev-period.label":
      "Prev. Period",
    "buildingreports.occupancy-report.stats-table.no-vacant.number-label":
      "{vacantSuites, number} ({vacantPercentage}%)",
    "buildingreports.occupancy-report.stats-table.prev-period.number-label":
      "{prevVacantSuites, number} ({prevVacantPercentage}%)",
    "buildingreports.occupancy-report.y-label": "Vacant Space",
    "buildingreports.suite-distribution-report.title.label":
      "SUITE DISTRIBUTION",
    "buildingreports.distribution-report.stats-table.space-type.label":
      "Space Type",
    "buildingreports.distribution-report.stats-table.suite-area.label":
      "Suite Area (sq. ft.)",
    "buildingreports.distribution-report.stats-table.no-of-suites.label":
      "No. of Suites",
    "buildingreports.distribution-report.stats-table.percentage-total.label":
      "% Total",
    "buildingreports.distribution-report.stats-table.area.number":
      "{area, number}",
    "buildingreports.distribution-report.stats-table.total-area.number":
      "{totalArea, number}",
    "buildingreports.distribution-report.stats-table.total-suite-area.number":
      "{totalSuiteArea, number}",
    "buildingreports.distribution-report.stats-table.report-total.label":
      "Report Total",
    "buildingreports.distribution-report.stats-table.total-suite-area.label":
      "Total Suite Area",

    "buildingreports.lease-expiry-report.title.label": "LEASE EXPIRY PROFILE",
    "buildingreports.lease-expiry-report.status.label": "Status",

    "buildingreports.largest-tenant-report.title.label": "LARGEST TENANT",
    "buildingreports.largest-tenant-report.stats-table.tenant.label": "Tenant",

    "buildingreports.vacant-suites-report.title.label":
      "NUMBER OF VACANT SUITES",
    "buildingreports.vacant-suites.stats-table.suite.label": "Suite",
    "buildingreports.vacant-suites.stats-table.views.label": "Views",
    "buildingreports.vacant-suites.stats-table.no-of-months-vacant.label":
      "No. of Months Vacant",
    "buildingreports.vacant-suites.stats-table.marketing-score.label":
      "Marketing Score",

    // Buildings - Building Information Page
    "buildinginfo.meta-header.stats.no-of-floors.label": "No. of Floors",
    "buildinginfo.meta-header.stats.no-of-suites.label": "No. of Suites",
    "buildinginfo.meta-header.stats.no-of-leases.label": "No. of Leases",
    "buildinginfo.meta-header.stats.rba.label": "RBA",
    "buildinginfo.meta-header.stats.occupancy.label": "Occupancy",
    "buildinginfo.meta-header.stats.avg-base-rent.label": "Avg. Base Rent",
    "buildinginfo.meta-header.stats.walt.label": "WALT",
    "buildinginfo.meta-header.stats.18-mo-l-roll.label": "18 Mo. L. Roll",
    "buildinginfo.meta-header.stats.total-available.label": "Total Available",

    "buildinginfo.meta-header.stats.no-of-floors.number": "{floors, number}",
    "buildinginfo.meta-header.stats.no-of-suites.number": "{suites, number}",
    "buildinginfo.meta-header.stats.no-of-leases.number": "{leases, number}",
    "buildinginfo.meta-header.stats.rba.number": "{rba, number}",
    "buildinginfo.meta-header.stats.occupancy.number": "{occupancy, number}%",
    "buildinginfo.meta-header.stats.walt.number": "{walt, number}",
    "buildinginfo.meta-header.stats.18-mo-l-roll.number":
      "{eighteenMoRoll, number}%",
    "buildinginfo.meta-header.stats.total-available.number":
      "{available, number}%",
    "buildinginfo.meta-header.stats.occupancy-sqft.number-label":
      "{occupancy_sqft, number} sq.ft.",
    "buildinginfo.meta-header.stats.per-month.label": "per month",
    "buildinginfo.meta-header.stats.18mo-roll-sqft.number-label":
      "{eighteenMoRollSqft, number} sq.ft.",
    "buildinginfo.meta-header.stats.available-sqft.number-label":
      "{available_sqft, number} sq.ft.",

    "buildinginfo.dialog.title.label": "Title",
    "buildinginfo.dialog.content.label": "Content",
    "buildinginfo.heading.button.new-heading.label": "New Heading",
    "buildinginfo.heading.popup.title.new-heading.label": "New Heading",
    "buildinginfo.info-section.description.title": "BUILDING DESCRIPTION",
    "buildinginfo.info-section.features.title": "FEATURES",
    "buildinginfo.info-section.amenities.title": "AMENITIES",
    "buildinginfo.info-section.sustainability.title": "SUSTAINABILITY",
    "buildinginfo.info-section.location.title": "Location",
    "buildinginfo.info-section.content.empty-editor.placeholder":
      "Enter content here...",

    "buildinginfo.announcements.popup.title.new-announcement.label":
      "New Announcement",
    "buildinginfo.announcements.panel.title.marketing-announcements.label":
      "Marketing Announcement",
    "buildinginfo.announcements.panel.title.marketing-announcements.sent.label":
      "Sent",
    "buildinginfo.announcements.panel.title.marketing-announcements.send-updates.label":
      "Send Updates",
    "buildinginfo.info-section.content.date-editor.placeholder":
      "Click to enter expiry date...",

    "buildinginfo.location-form.building-name.label": "Building Name",
    "buildinginfo.location-form.street.label": "Street",
    "buildinginfo.location-form.address.label": "Address",
    "buildinginfo.location-form.region.label": "Region",
    "buildinginfo.location-form.neighbourhood.label": "Neighbourhood",
    "buildinginfo.location-form.city.label": "City",
    "buildinginfo.location-form.sub-region.label": "Sub Region",
    "buildinginfo.location-form.province-state.label": "Province/State",
    "buildinginfo.location-form.node.label": "Node",
    "buildinginfo.location-form.postal-zip.label": "Postal/Zip Code",
    "buildinginfo.location-form.group.label": "Group",
    "buildinginfo.location-form.country.label": "Country",
    "buildinginfo.location-form.button.reset.label": "Reset",
    "buildinginfo.location-form.button.save.label": "Save",
    "buildinginfo.building-info-section.dnd.drop.placeholder": "Drop here",

    // Buildings - Building Specifications Page
    "buildingspec.form-section.title.building_info": "BUILDING INFORMATION",
    "buildingspec.form-section.title.general_information": "GENERAL",
    "buildingspec.form-section.title.certifications": "CERTIFICATIONS",
    "buildingspec.form-section.title.tenant_costs": "TENANTS COSTS",
    "buildingspec.form-section.title.size_information": "SIZE INFORMATION",
    "buildingspec.form-section.title.construction_information":
      "CONSTRUCTION INFORMATION",
    "buildingspec.form-section.title.elevators": "ELEVATOR INFORMATION",
    "buildingspec.form-section.title.safety_and_access_information":
      "SAFETY AND ACCESS INFORMATION",
    "buildingspec.form-section.title.parking_information":
      "PARKING INFORMATION",
    "buildingspec.form-section.title.building_virtual_tours": "VIRTUAL TOUR",
    "buildingspec.form-section.title.anchor_tenants": "ANCHOR TENANTS",
    "buildingspec.form-section.title.public_transit_information":
      "PUBLIC TRANSIT INFORMATION",
    "buildingspec.form-section.title.site_description": "SITE DESCRIPTION",
    "buildingspec.form-section.title.technical_information":
      "TECHNICAL INFORMATION",
    "buildingspec.form-section.title.land_information": "LAND INFORMATION",
    "buildingspec.form-section.title.serviced_information":
      "SERVICED INFORMATION",
    "buildingspec.form-section.title.yearly_realty_taxes":
      "YEARLY REALTY TAXES",
    "buildingspec.form-section.title.amenities": "AMENITIES",
    "buildingspec.form-section.title.services": "SERVICES",
    "buildingspec.form-section.title.retail_profile": "RETAIL PROFILE",
    "buildingspec.form-section.title.operating_horus": "OPERATING HOURS",
    "buildingspec.form-section.title.demographics": "DEMOGRAPHICS",

    // Industrial Building Type
    "buildingspec.form-section.field.label.name": "Building Name",
    "buildingspec.form-section.field.label.group": "Group",
    "buildingspec.form-section.field.label.fund": "Ownership Group",
    "buildingspec.form-section.field.label.region": "Region",
    "buildingspec.form-section.field.label.sub_region": "Sub Region",
    "buildingspec.form-section.field.label._type": "Building Type",
    "buildingspec.form-section.field.label.is_owned_building":
      "Is Owned Building ?",
    "buildingspec.form-section.field.label.is_managed": "Is Managed Building ?",
    "buildingspec.form-section.field.label.import_id": "Import Id",
    "buildingspec.form-section.field.label.units": "Measurement Units",
    "buildingspec.form-section.field.label.exterior_finish": "Exterior Finish",
    "buildingspec.form-section.field.label.roofing_type": "Roofing Type",
    "buildingspec.form-section.field.label.available_voltage":
      "Available Voltage",
    "buildingspec.form-section.field.label.heating_description":
      "Heating Description",
    "buildingspec.form-section.field.label.power_description":
      "Power Description",
    "buildingspec.form-section.field.label.available_amperage":
      "Available Amperage",
    "buildingspec.form-section.field.label.new_development":
      "New Development ?",
    "buildingspec.form-section.field.label.year_built": "Built (yr)",
    "buildingspec.form-section.field.label.year_last_renovated":
      "Last Renovated (yr)",
    "buildingspec.form-section.field.label.boma_best": "BOMA Best",
    "buildingspec.form-section.field.label.certificates": "Certifications",
    "buildingspec.form-section.field.label.leed": "LEED",
    "buildingspec.form-section.field.label.building_management":
      "Building Management",
    "buildingspec.form-section.field.label.building_website":
      "Building Website",
    "buildingspec.form-section.field.label.building_zoned": "Building Zoned",
    "buildingspec.form-section.field.label.surface_stalls":
      "Number of Surface Stalls",
    "buildingspec.form-section.field.label.above_ground_stalls":
      "Number of Above Ground Stalls",
    "buildingspec.form-section.field.label.below_ground_stalls":
      "Number of Below Ground Stalls",
    "buildingspec.form-section.field.label.trailer_parking_stalls":
      "Number of Trailer Parking Stalls",   
    "buildingspec.form-section.field.label.total_stalls":
      "Total Parking Stalls",
    "buildingspec.form-section.field.label.parking_description":
      "Parking Description",
    "buildingspec.form-section.field.label.fire_detection_system":
      "Fire Detection System ?",
    "buildingspec.form-section.field.label.sprinkler_system":
      "Sprinkler System ?",
    "buildingspec.form-section.field.label.security_system":
      "Security System ?",
    "buildingspec.form-section.field.label.manned_security":
      "Manned Security ?",
    "buildingspec.form-section.field.label.safety_and_access_description":
      "Safety and Access Description",
    "buildingspec.form-section.field.label.average_office_percentage_area":
      "Average Office % Area",
    "buildingspec.form-section.field.label.number_of_buildings":
      "Number of Buildings",
    "buildingspec.form-section.field.label.number_of_units": "Number of Units",
    "buildingspec.form-section.field.label.primary_usage": "Primary Usage",
    "buildingspec.form-section.field.label.secondary_usage": "Secondary Usage",
    "buildingspec.form-section.field.label.site_zoning_description":
      "Site Zoning Description",
    "buildingspec.form-section.field.label.site_coverage": "Site Coverage",
    "buildingspec.form-section.field.label.total_acreage": "Total Acreage",
    "buildingspec.form-section.field.label.total_industrial_space":
      "Total Industrial Space",
    "buildingspec.form-section.field.label.available_industrial_space":
      "Available Industrial Space",
    "buildingspec.form-section.field.label.total_office_space":
      "Total Office Space",
    "buildingspec.form-section.field.label.available_office_space":
      "Available Office Space",
    "buildingspec.form-section.field.label.total_other_space":
      "Total Other Space",
    "buildingspec.form-section.field.label.available_other_space":
      "Available Other Space",
    "buildingspec.form-section.field.label.total_space": "Total Space",
    "buildingspec.form-section.field.label.total_available_space":
      "Total Available Space",
    "buildingspec.form-section.field.label.compute_available_space":
      "Compute Available Space from Stacking Plan ?",
    "buildingspec.form-section.field.label.occupied_space": "Occupied Space",
    "buildingspec.form-section.field.label.manufacturing_size":
      "Manufacturing Size",
    "buildingspec.form-section.field.label.warehouse_size": "Warehouse Size",
    "buildingspec.form-section.field.label.ceiling_height": "Clear Height",
    "buildingspec.form-section.field.label.max_door_height":
      "Maximum Door Height",
    "buildingspec.form-section.field.label.shipping_doors_drive_in":
      "Number of Drive-in Doors",
    "buildingspec.form-section.field.label.shipping_doors_dock":
      "Number of Dock-high Doors",
    "buildingspec.form-section.field.label.marshalling_area":
      "Marshalling Area",
    "buildingspec.form-section.field.label.dolly_pad": "Dolly Pad",
    "buildingspec.form-section.field.label.storage_description":
      "Storage Description",
    "buildingspec.form-section.field.label.rail_loading": "Rail Loading ?",
    "buildingspec.form-section.field.label.outside_storage":
      "Outside Storage ?",
    "buildingspec.form-section.field.label.building_plan": "Building Plan ?",
    "buildingspec.form-section.field.label.peer_review": "Peer Review ?",
    "buildingspec.form-section.field.label.survey": "Survey ?",
    "buildingspec.form-section.field.label.dry_cleaning_solvents":
      "Dry Cleaning Solvents ?",
    "buildingspec.form-section.field.label.asbestos_free": "Asbestos Free ?",
    "buildingspec.form-section.field.label.net_rent": "Net Rent",
    "buildingspec.form-section.field.label.utilities": "Utilities",
    "buildingspec.form-section.field.label.operating_costs": "Operating Costs",
    "buildingspec.form-section.field.label.realty_tax": "Realty Tax",
    "buildingspec.form-section.field.label.other_tenant_costs":
      "Other Tenant Costs",
    "buildingspec.form-section.field.label.total_additional_rent":
      "Total Additional Rent (excludes Net Rent)",
    "buildingspec.form-section.field.label.tenant_costs_description":
      "Tenant Costs Description",

    // Land Building Type
    "buildingspec.form-section.field.label.sale_or_lease": "Sale or Lease ?",
    "buildingspec.form-section.field.label.land_type": "Land Type",
    "buildingspec.form-section.field.label.property_sub_type":
      "Property Sub Type",
    "buildingspec.form-section.field.label.proposed_use": "Proposed Use",
    "buildingspec.form-section.field.label.zoning": "Zoning",
    "buildingspec.form-section.field.label.number_of_lots": "Number of Lots",
    "buildingspec.form-section.field.label.road_frontage": "Road Frontage",
    "buildingspec.form-section.field.label.road_surface": "Road Surface",
    "buildingspec.form-section.field.label.waterfront": "Waterfront",
    "buildingspec.form-section.field.label.permitted_uses": "Permitted Uses",
    "buildingspec.form-section.field.label.sale_price_total":
      "Total Sale Price",
    "buildingspec.form-section.field.label.sale_price_per_acre": "Sale Price",
    "buildingspec.form-section.field.label.lease_rate_per_acre": "Lease Rate",
    "buildingspec.form-section.field.label.land_area_sqft": "Land Area",
    "buildingspec.form-section.field.label.land_area_acres": "Land Area",
    "buildingspec.form-section.field.label.building_area_sqft": "Building Area",
    "buildingspec.form-section.field.label.building_area_acres":
      "Building Area",
    "buildingspec.form-section.field.label.zoning_description":
      "Zoning Description",
    "buildingspec.form-section.field.label.water_city": "Water - City ?",
    "buildingspec.form-section.field.label.water_well": "Water - Well ?",
    "buildingspec.form-section.field.label.sewer_septic": "Sewer - Septic ?",
    "buildingspec.form-section.field.label.sewer_municipal":
      "Sewer - Municipal ?",
    "buildingspec.form-section.field.label.electrical": "Electrical ?",
    "buildingspec.form-section.field.label.natural_gas": "Natural Gas ?",
    "buildingspec.form-section.field.label.fibre_optic": "Fibre Optic ?",
    "buildingspec.form-section.field.label.satellite": "Satellite ?",
    "buildingspec.form-section.field.label.internet": "Internet ?",
    "buildingspec.form-section.field.label.telephone": "Telephone ?",
    "buildingspec.form-section.field.label.transit_highway":
      "Transit / Highway Description",
    "buildingspec.form-section.field.label.service_description":
      "Service Description",
    "buildingspec.form-section.field.label.location": "Location ?",
    "buildingspec.form-section.field.label.traffic_count": "Traffic Count ?",

    // Office Building Type
    "buildingspec.form-section.field.label.anchor_tenant_one":
      "Anchor Tenant One",
    "buildingspec.form-section.field.label.anchor_tenant_two":
      "Anchor Tenant Two",
    "buildingspec.form-section.field.label.anchor_tenant_three":
      "Anchor Tenant Three",
    "buildingspec.form-section.field.label.anchor_tenant_four":
      "Anchor Tenant Four",
    "buildingspec.form-section.field.label.anchor_tenant_five":
      "Anchor Tenant Five",
    "buildingspec.form-section.field.label.anchor_tenant_six":
      "Anchor Tenant Six",
    "buildingspec.form-section.field.label.typical_power": "Typical Power",
    "buildingspec.form-section.field.label.hvac_after_hours_cost":
      "HVAC After Hours Cost",
    "buildingspec.form-section.field.label.lighting": "Lighting",
    "buildingspec.form-section.field.label.hvac_hours_of_operation":
      "HVAC Hours of Operation",
    "buildingspec.form-section.field.label.interior_wall_type":
      "Interior Wall Type",
    "buildingspec.form-section.field.label.hvac_distribution_system_description":
      "HVAC Distribution System Description",
    "buildingspec.form-section.field.label.washrooms_per_floor":
      "Washrooms per Floor",
    "buildingspec.form-section.field.label.satellite_dish_capable":
      "Satellite Dish Capability ?",
    "buildingspec.form-section.field.label.fibre_optic_capable":
      "Fibre Optic Capability ?",
    "buildingspec.form-section.field.label.shipping_receiving":
      "Shipping/Receiving ?",
    "buildingspec.form-section.field.label.emergency_generator":
      "Emergency Generator ?",
    "buildingspec.form-section.field.label.number_of_high_rise":
      "Number of High Rise Elevators",
    "buildingspec.form-section.field.label.number_of_parking":
      "Number of Parking Elevators",
    "buildingspec.form-section.field.label.number_of_mid_rise":
      "Number of Mid Rise Elevators",
    "buildingspec.form-section.field.label.number_of_freight":
      "Number of Freight Elevators",
    "buildingspec.form-section.field.label.number_of_low_rise":
      "Number of Low Rise Elevators",
    "buildingspec.form-section.field.label.building_class": "Building Class",
    "buildingspec.form-section.field.label.surface_stall_ratio":
      "Surface Stall Ratio",
    "buildingspec.form-section.field.label.above_ground_ratio":
      "Above Ground Ratio",
    "buildingspec.form-section.field.label.below_ground_ratio":
      "Below Ground Ratio",
    "buildingspec.form-section.field.label.parking_cost_per_day":
      "Parking Cost Per Day",
    "buildingspec.form-section.field.label.parking_cost_per_month":
      "Parking Cost Per Month",
    "buildingspec.form-section.field.label.surface_transit_route":
      "Surface Transit Route ?",
    "buildingspec.form-section.field.label.direct_subway_access":
      "Direct Subway Access ?",
    "buildingspec.form-section.field.label.public_transit_description":
      "Public Transit Description",
    "buildingspec.form-section.field.label.barrier_free_access":
      "Barrier Free Access ?",
    "buildingspec.form-section.field.label.number_of_floors":
      "Number of Floors",
    "buildingspec.form-section.field.label.typical_high_rise_floor":
      "Typical High Rise Floor",
    "buildingspec.form-section.field.label.typical_low_rise_floor":
      "Typical Low Rise Floor",
    "buildingspec.form-section.field.label.typical_high_rise_gross_up":
      "Typical High Rise Gross Up",
    "buildingspec.form-section.field.label.typical_low_rise_gross_up":
      "Typical Low Rise Gross Up",
    "buildingspec.form-section.field.label.typical_unit_size":
      "Typical Unit Size",
    "buildingspec.form-section.field.label.total_retail_space":
      "Total Retail Space",
    "buildingspec.form-section.field.label.available_retail_space":
      "Available Retail Space",
    "buildingspec.form-section.field.label.total_occupied_space":
      "Total Occupied Space",
    "buildingspec.form-section.field.label.largest_contiguous_available":
      "Largest Contiguous Available Space",

    // Resident Building Type
    "buildingspec.form-section.field.label.onsite_superintendent":
      "Onsite Superintendent ?",
    "buildingspec.form-section.field.label.fitness_centre": "Fitness Centre ?",
    "buildingspec.form-section.field.label.swimming_pool": "Swimming Pool ?",
    "buildingspec.form-section.field.label.multipurpose_room":
      "Multipurpose Room ?",
    "buildingspec.form-section.field.label.locker_and_bike_storage":
      "Locker and Bike Storage ?",
    "buildingspec.form-section.field.label.ac_in_unit": "AC - In Unit ?",
    "buildingspec.form-section.field.label.pets": "Pet Friendly ?",
    "buildingspec.form-section.field.label.recycling": "Recycling ?",
    "buildingspec.form-section.field.label.picnic_area": "Picnic Area ?",
    "buildingspec.form-section.field.label.playground": "Playground ?",
    "buildingspec.form-section.field.label.laundry_in_unit":
      "Laundry - In Unit ?",
    "buildingspec.form-section.field.label.laundry_shared":
      "Laundry - Shared ?",
    "buildingspec.form-section.field.label.laundry_description":
      "Laundry - Description",
    "buildingspec.form-section.field.label.rooftop_deck": "Rooftop Patio ?",
    "buildingspec.form-section.field.label.business_centre":
      "Business Centre ?",
    "buildingspec.form-section.field.label.bike_rack": "Bike Rack ?",
    "buildingspec.form-section.field.label.barbecue": "Barbecue ?",
    "buildingspec.form-section.field.label.sauna": "Sauna ?",
    "buildingspec.form-section.field.label.pet_area": "Pet Area ?",
    "buildingspec.form-section.field.label.pet_washing_station":
      "Pet Washing Station ?",
    "buildingspec.form-section.field.label.games_room": "Games Room ?",
    "buildingspec.form-section.field.label.building_type": "Building Type",
    "buildingspec.form-section.field.label.residential_building_type":
      "Residential Building Type",
    "buildingspec.form-section.field.label.visitor_parking_stalls":
      "Number of Visitor Stalls",
    "buildingspec.form-section.field.label.cost_per_month": "Cost per Month",
    "buildingspec.form-section.field.label.controlled_access":
      "Controlled Access ?",
    "buildingspec.form-section.field.label.intercom": "Intercom ?",
    "buildingspec.form-section.field.label.gated_entry": "Gated Entry ?",
    "buildingspec.form-section.field.label.twenty_four_hour_maintenance":
      "24 Hr Maintenance ",
    "buildingspec.form-section.field.label.assisted_living":
      "Assisted Living ?",
    "buildingspec.form-section.field.label.online_rent_payment":
      "Online Rent Payment",
    "buildingspec.form-section.field.label.online_maintenance_portal":
      "Online Maintenance Portal",
    "buildingspec.form-section.field.label.units_in_building_bachelor":
      "Number of Units - Bachelor",
    "buildingspec.form-section.field.label.units_in_building_one_bedroom":
      "Number of Units - One Bedroom",
    "buildingspec.form-section.field.label.units_in_building_two_bedroom":
      "Number of Units - Two Bedroom",
    "buildingspec.form-section.field.label.units_in_building_three_bedroom":
      "Number of Units - Three Bedroom",
    "buildingspec.form-section.field.label.units_in_building_four_plus_bedroom":
      "Number of Units - Four+ Bedrooms",
    "buildingspec.form-section.field.label.total_number_of_units":
      "Total Number of Units",
    "buildingspec.form-section.field.label.gross_floor_area":
      "Gross Floor Area",
    "buildingspec.form-section.field.label.utilities_included_cable":
      "Utilities Included - Cable",
    "buildingspec.form-section.field.label.utilities_included_ac":
      "Utilities Included - AC",
    "buildingspec.form-section.field.label.utilities_included_heat":
      "Utilities Included - Heat",
    "buildingspec.form-section.field.label.utilities_included_electricity":
      "Utilities Included - Electricity",
    "buildingspec.form-section.field.label.utilities_included_garbage":
      "Utilities Included - Garbage",
    "buildingspec.form-section.field.label.utilities_included_internet":
      "Utilities Included - Internet",
    "buildingspec.form-section.field.label.utilities_included_none":
      "Utilities Included - None",
    "buildingspec.form-section.field.label.lease_terms": "Lease Terms",
    "buildingspec.form-section.field.label.lot_size": "Lot Size",
    "buildingspec.form-section.field.label.lot_dimensions": "Lot Dimensions",

    // Retail Building Type
    "buildingspec.form-section.field.label.pta_population":
      "Primary Trade Area - Population",
    "buildingspec.form-section.field.label.pta_number_of_households":
      "Primary Trade Area - Number of Households",
    "buildingspec.form-section.field.label.pta_average_household_income":
      "Primary Trade Area - Household Income",
    "buildingspec.form-section.field.label.sta_population":
      "Secondary Trade Area - Population",
    "buildingspec.form-section.field.label.sta_number_of_households":
      "Secondary Trade Area - Number of Households",
    "buildingspec.form-section.field.label.sta_average_household_income":
      "Secondary Trade Area - Household Income",
    "buildingspec.form-section.field.label.cp_median_age":
      "Customer Profile - Median Age",
    "buildingspec.form-section.field.label.cp_persons_per_household":
      "Customer Profile - Persons Per Household",
    "buildingspec.form-section.field.label.cp_average_household_income":
      "Customer Profile - Average Household Income",
    "buildingspec.form-section.field.label.annual_pedestrian_traffic":
      "Annual Pedestrian Traffic",
    "buildingspec.form-section.field.label.demographic_source":
      "Demographic Source",
    "buildingspec.form-section.field.label.operating_hours_monday":
      "Operating Hours - Monday",
    "buildingspec.form-section.field.label.operating_hours_tuesday":
      "Operating Hours - Tuesday",
    "buildingspec.form-section.field.label.operating_hours_wednesday":
      "Operating Hours - Wednesday",
    "buildingspec.form-section.field.label.operating_hours_thursday":
      "Operating Hours - Thursday",
    "buildingspec.form-section.field.label.operating_hours_friday":
      "Operating Hours - Friday",
    "buildingspec.form-section.field.label.operating_hours_saturday":
      "Operating Hours - Saturday",
    "buildingspec.form-section.field.label.operating_hours_sunday":
      "Operating Hours - Sunday",
    "buildingspec.form-section.field.label.operating_hours_description":
      "Operating Hours Description",
    "buildingspec.form-section.field.label.number_of_stores":
      "Number of Stores",
    "buildingspec.form-section.field.label.food_court": "Food Court ?",
    "buildingspec.form-section.field.label.number_of_food_units":
      "Number of Food Units",
    "buildingspec.form-section.field.label.food_court_seating":
      "Food Court Seating",
    "buildingspec.form-section.field.label.total_retail_space_CRU":
      "Total Retail Space - (CRU)",
    "buildingspec.form-section.field.label.mall_type": "Mall Type",


    // Buildings - Building Media Page
    "buildingmedia.contents.no-media.info": "No media available",
    "buildingmedia.upload-dialog.title": "Upload Media",
    "buildingmedia.upload-dialog.drop-area.placeholder":
      "Drag & Drop a File Here or Click",
    "buildingmedia.upload-dialog.upload-limit-warning.images":
      "Upload limit for images is 20 MB",
    "buildingmedia.upload-dialog.upload-limit-warning.videos":
      "Upload limit for videos is 500 MB",
    "buildingmedia.upload-dialog.upload-limit-warning.pdf":
      "Upload limit for PDFs is 20 MB",
    "buildingmedia.upload-dialog.media-details.info.label": "Info",
    "buildingmedia.upload-dialog.media-details.file-name.label": "File name",
    "buildingmedia.upload-dialog.media-details.title.label": "Title",
    "buildingmedia.upload-dialog.media-details.alt-text.label": "Alt Text",
    "buildingmedia.upload-dialog.media-details.uploaded.label": "Uploaded",
    "buildingmedia.upload-dialog.media-details.tag.label": "Tag",
    "buildingmedia.upload-dialog.media-details.button.set-as-thumbnail.label":
      "Set as thumbnail",
    "buildingmedia.media-save.success-msg":
      "Building media updated successfully!",
    "buildingmedia.media-delete.confirmation-msg":
      "Are you sure you want to delete this media item ?",
    "buildingmedia.thumbnail-unpublish.published-msg":
      "Remove 'Thumbnail' label to unpublish image",
    "buildingmedia.media-preview.page.label": "Page",
    "buildingmedia.media-preview.preview-unavailable": "Preview not available",
    "suitemedia.media-save.success-msg": "Suite media updated successfully!",
    "suitemedia.header.page.title": "Marketing Media for {suiteIdentifier}",

    // Buildings - Building Contacts Page
    "contacts.meta-header.controls.btn.add-contact": "Add Contact",
    "contacts.meta-header.controls.btn.add-leasing-contact":
      "Add Leasing Contact",
    "contacts.meta-header.controls.btn.add-property-contact":
      "Add Property Contact",
    "contacts.list-view.header.name.label": "Name",
    "contacts.list-view.table.header.phone.label": "Phone",
    "contacts.list-view.table.header.email.label": "Email",
    "contacts.list-view.table.header.company.label": "Company",
    "contacts.list-view.table.header.title.label": "Title",
    "contacts.list-view.table.header.actions.label": "Actions",
    "contacts.list-view.contact-delete.confirmation":
      "Are you sure you want to delete the contact",
    "contacts.list-view.contact-delete.success-msg":
      "Contact deleted successfully!",
    "contacts.list-view.contact-update.success-msg":
      "Contact updated successfully!",
    "contacts.add-new-contact.dialog.title": "Add a contact to building",
    "contacts.add-new-contact.dialog.instructions":
      "When you add a user here, they become a point of contact for all listings appended to this building.",
    "contacts.add-new-contact.dialog.search.placeholder": "Search Contact...",
    "contacts.add-new-contact.contact-add.success-msg":
      "Contacts added successfully!",
    "contacts.add-new-contact.dialog.selected-contacts-panel.title":
      "SELECTED CONTACTS",
    "contacts.add-new-contact.dialog.selected-contacts-panel.no-selections.info":
      "No contacts selected",

    // Settings - Your Account
    "settings.context-pane.title.settings.label": "Settings",
    "settings.context-pane.tabs.your-account.label": "Your Account",
    "settings.context-pane.tabs.users.label": "Users",
    "settings.context-pane.tabs.company.label": "Company",
    "settings.context-pane.tabs.sharing-content.label": "Sharing Content",
    "settings.context-pane.tabs.building-specs.label": "Building Specs",
    "settings.context-pane.tabs.super-admin.label": "Super Admin",

    "settings.account.panel-title.label": "ACCOUNT INFORMATION",
    "settings.account.username.label": "Username",
    "settings.account.realname.label": "Real Name",
    "settings.account.phone.label": "Phone",
    "settings.account.email.label": "Email",

    "settings.account.existing-password.label": "Existing Password",
    "settings.account.new-password.label": "New Password",
    "settings.account.confirm-password.label": "Confirm Password",
    "settings.account.save-changes.button": "Save Changes",

    "settings.account.readonly.panel-title.label": "YOUR CURRENT INFORMATION",
    "settings.account.last-updated.label": "Last Updated",

    "settings.account.form.validation.password-no-match":
      "Does not match the new password specified above",
    "settings.account.form.validation.current-pwd":
      "Please enter the current password",
    "settings.account.form.validation.new-pwd": "Please enter the new password",
    "settings.account.form.validation.retype-new-pwd":
      "Please enter the new password again",
    "settings.account.password-change.success-msg":
      "Password changed successfully!",
    "settings.account.user-update.success-msg":
      "User details updated successfully!",
    "settings.account.app-version.label": "Site Version",
    // Settings - Users
    "settings.users.users-list-view.table.header.name.label": "Name",
    "settings.users.users-list-view.table.header.username.label": "Username",
    "settings.users.users-list-view.table.header.email.label": "Email",
    "settings.users.users-list-view.table.header.role.label": "Role",
    "settings.users.users-list-view.table.header.actions.label": "Actions",
    "settings.users.users-list-view.table.header.transfer-from.label":
      "Transfer From",
    "settings.users.users-list-view.table.header.transfer-to.label":
      "Transfer To",
    "settings.users.users-list-view.table.header.copy-from.label": "Copy From",
    "settings.users.users-list-view.table.header.copy-to.label": "Copy To",
    "settings.users.users-list-view.transfer-mode.title":
      "Select Users to Transfer Building Permissions and Contact Settings.",
    "settings.users.users-list-view.copy-mode.title":
      "Select Users to Copy Buildings",
    "settings.users.users-list-view.transfer-mode.confirmation-dialog.title":
      "Confirmation",
    "settings.users.users-list-view.transfer-mode.confirmation.body":
      "By clicking on 'Yes', you are confirming that you are {action} {from}'s assigned buildings to {to}",
    "settings.users.users-list-view.transfer-mode.success-msg":
      "Building assignments {action} successfully!",
    "settings.users.users-list-view.user-delete.confirmation":
      "Are you sure you want to delete the user",
    "settings.users.users-list-view.search-bar.placeholder-text":
      "Type here to search for a user...",

    // Settings - Users - Add New User
    "settings.users.add-new-user.table.header.building.label": "Building",
    "settings.users.add-new-user.table.header.address.label": "Address",
    "settings.users.add-new-user.table.header.city.label": "City",
    "settings.users.add-new-user.page.header.title": "Add New User",
    "settings.users.add-new-user.step.profile-perm.title":
      "Profile & Permissions",
    "settings.users.add-new-user.step.perm-buildings.title":
      "Permitted Buildings",
    "settings.users.add-new-user.section.account-info.title":
      "ACCOUNT INFORMATION",
    "settings.users.add-new-user.section.account-info.form.username.label":
      "Username",
    "settings.users.add-new-user.section.account-info.form.email.label":
      "Email",
    "settings.users.add-new-user.section.account-info.form.first-name.label":
      "First Name",
    "settings.users.add-new-user.section.account-info.form.last-name.label":
      "Last Name",
    "settings.users.add-new-user.section.account-info.form.title.label":
      "Title",
    "settings.users.add-new-user.section.account-info.form.company.label":
      "Company",
    "settings.users.add-new-user.section.account-info.form.phone.label":
      "Phone",
    "settings.users.add-new-user.section.account-info.form.language.label":
      "Language",
    "settings.users.add-new-user.section.account-info.form.language.E":
      "English",
    "settings.users.add-new-user.section.account-info.form.language.F":
      "French",
    "settings.users.add-new-user.section.account-info.form.password.label":
      "Password",
    "settings.users.add-new-user.section.account-info.form.confirm-password.label":
      "Confirm Password",
    "settings.users.add-new-user.section.user-role.title": "USER ROLE",
    "settings.users.add-new-user.section.user-role.CA.desc":
      "Can overwrite and manually edit all content.",
    "settings.users.add-new-user.section.user-role.LA.desc":
      "Can read and write. But cannot override automation edits.",
    "settings.users.add-new-user.section.user-role.LB.desc":
      "Can read with limited writing permissions.",
    "settings.users.add-new-user.section.user-role.LB.sub-section.stacking-plan-access":
      "Stacking Plan Access",
    "settings.users.add-new-user.section.user-role.LB.sub-section.stacking-plan-access.RO.label":
      "Read-only",
    "settings.users.add-new-user.section.user-role.LB.sub-section.stacking-plan-access.MP.label":
      "Marketing profile only",
    "settings.users.add-new-user.section.perm-buildings.title":
      "PERMITTED BUILDINGS",
    "settings.users.add-new-user.section.perm-buildings.instructions":
      "Please select permitted buildings",
    "settings.users.add-new-user.section.perm-buildings.search.label": "Search",
    "settings.users.add-new-user.section.perm-buildings.selected-buildings.label":
      "SELECTED BUILDINGS",
    "settings.users.add-new-user.section.perm-buildings.no-selection.label":
      "No buildings selected",

    // Settings - User Details View
    "settings.users.users-details.profile.updated.label": "Updated",
    "settings.users.users-details.profile.company.label": "Company",
    "settings.users.users-details.profile.title.label": "Title",
    "settings.users.users-details.profile.role.label": "Role",
    "settings.users.users-details.profile.email.label": "Email",
    "settings.users.users-details.profile.phone.label": "Phone",
    "settings.users.users-details.profile.address.label": "Address",
    "settings.users.users-details.buildings.assigned-buildings.label":
      "PERMITTED BUILDINGS",
    "settings.users.users-details.buildings.building.label": "Building",
    "settings.users.users-details.buildings.client.label": "Client",
    "settings.users.users-details.buildings.since.label": "Since",
    "settings.users.users-details.buildings.action.label": "Action",
    "settings.users.users-details.buildings.no-buildings-assigned.info":
      "No buildings assigned",
    "settings.users.users-details.role.user-role.label": "USER ROLE",
    "settings.users.users-details.add-buildings-dialog.title":
      "Permitted Buildings",
    "settings.users.users-details.add-buildings-dialog.sub-title":
      "If no buildings are selected {userFirstName} will have access to the full portfolio.",
    "settings.users.users-details.add-buildings-dialog.instructions":
      "Please select which buildings {userFirstName} can access.",
    "settings.users.users-details.add-buildings-dialog.search.label": "Search",
    "settings.users.users-details.add-buildings-dialog.selected-buildings.label":
      "SELECTED BUILDINGS",
    "settings.users.users-details.add-buildings-dialog.no-buildings-selected.info":
      "No buildings selected",
    "settings.users.users-details.delete-building-assignment.confirmation-msg":
      "Are you sure you want to delete this building assignment ?",

    // Export Dialog
    "virtual_tours.alert.update-success.msg": "Update Successful",
    "virtual_tours.delete.confirmation-msg":
      "Are you sure you want to delete this Virtual Tour?",
    "virtual_tours.validation.message":
      'Make sure to add "https://" or "http://" to your URL for it to appear successfully in your leasing pages.'
  },

  fr: {
    /// FRENCH CODES
    "codes.listing-status.L": "listé",
    "codes.listing-status.C": "Contactez-nous pour les disponibilités",
    "codes.listing-status.F": "Entièrement loués",

    "codes.building-type.O": "Bureau",
    "codes.building-type.E": "Résidentiel",
    "codes.building-type.R": "Commerce de détail",
    "codes.building-type.I": "Industriel",
    "codes.building-type.L": "Terrain",

    "codes.buildings.pub-status.P": "Publié",
    "codes.buildings.pub-status.NP": "Non publiés",

    "codes.buildings.fav-status.F": "Favoris",
    "codes.buildings.fav-status.NF": "Non favoris",

    "codes.buildings.archived-status.A": "Archivé",
    "codes.buildings.archived-status.NA": "Non archivés",

    "codes.suite-type.I": "Industriel",
    "codes.suite-type.O": "Bureau",
    "codes.suite-type.E": "Résidentiel",
    "codes.suite-type.R": "Commerce de détail",
    "codes.suite-type.S": "Maison de retraite",
    "codes.suite-type.Z": "Locatif (autre)",
    "codes.suite-type.L": "Terrain",

    "codes.suite-availability-type.P": "Disponible",
    "codes.suite-availability-type.PR": "Privé",
    "codes.suite-availability-type.L": "Loué",
    "codes.suite-availability-type.C": "Engagé",
    "codes.suite-availability-type.PE": "En ettente",

    "codes.suite-state.C": "Engagé",
    "codes.suite-state.L": "Loué",
    "codes.suite-state.P": "Disponible",
    "codes.suite-state.PR": "Privé",
    "codes.suite-state.PE": "En ettente",

    "codes.lease.availability.leased": "Loué",
    "codes.lease.availability.m2m": "Bail mensuel",
    "codes.lease.availability.expired": "Expiré",

    "codes.residential-suite-type.B": "Studio",
    "codes.residential-suite-type.1": "Une chambre",
    "codes.residential-suite-type.2": "Deux chambres",
    "codes.residential-suite-type.3": "Trois chambres",
    "codes.residential-suite-type.4": "Quatre chambres",
    "codes.residential-suite-type.5": "Cinq chambres+",
    "codes.residential-suite-type.T": "Maison de ville",

    "codes.suite-condition-type.F": "Complètement construite",
    "codes.suite-condition-type.7": "75 % complète",
    "codes.suite-condition-type.5": "50 % complète",
    "codes.suite-condition-type.1": "10 % complète",
    "codes.suite-condition-type.B": "Bâtiment de base",

    "codes.residential-building-type.C": "Condominium",
    "codes.residential-building-type.L": "Étages inférieurs",
    "codes.residential-building-type.M": "Étages moyens",
    "codes.residential-building-type.H": "étages Élevés",
    "codes.residential-building-type.T": "Maison de ville",
    "codes.residential-building-type.B": "Mobile", // TODO

    "codes.user-types.AA": "Administrateur Arcestra",
    "codes.user-types.CA": "Administrateur de compagnie",
    "codes.user-types.LA": "Administrateur de bail",
    "codes.user-types.LB": "Courtier en crédit-bail",

    "codes.exterior-wall-types.B": "Brique",
    "codes.exterior-wall-types.S": "Pierre",
    "codes.exterior-wall-types.M": "Métal",
    "codes.exterior-wall-types.G": "Verry",
    "codes.exterior-wall-types.T": "Stuc",
    "codes.exterior-wall-types.P": "Béton Pré-coulé",
    "codes.exterior-wall-types.O": "Autres",

    "codes.roofing-types.M": "Métal",
    "codes.roofing-types.B": "Membrane",
    "codes.roofing-types.T": "Tuile",
    "codes.roofing-types.C": "Béton",
    "codes.roofing-types.O": "Autres",

    "codes.boma-types.P": "BOMA - Platine",
    "codes.boma-types.G": "BOMA - Or",
    "codes.boma-types.S": "BOMA - Argent",
    "codes.boma-types.B": "BOMA - Bronze",
    "codes.boma-types.C": "BOMA - Certifié",

    "codes.bomasustainable-types.P": "BOMA Durables - Platine",
    "codes.bomasustainable-types.G": "BOMA Durables - Or",
    "codes.bomasustainable-types.S": "BOMA Durables - Argent",
    "codes.bomasustainable-types.B": "BOMA Durables - Bronze",
    "codes.bomasustainable-types.C": "BOMA Durables - De base",

    "codes.leed-types.P": "LEED - Platine",
    "codes.leed-types.G": "LEED - Or",
    "codes.leed-types.S": "LEED - Argent",
    "codes.leed-types.C": "LEED - CertifiéL",
    "codes.leed-types.L": "LEED - Certifié Projet",
    "codes.leed-types.Z": "LEED - Leed Zéro",

    "codes.wired-types.P": "WiredScore - Platine",
    "codes.wired-types.G": "WiredScore - Or",
    "codes.wired-types.S": "WiredScore - Argent",
    "codes.wired-types.C": "WiredScore - Certifié",

    "codes.smartscore-types.P": "SmartScore - Platine",
    "codes.smartscore-types.G": "SmartScore - Or",
    "codes.smartscore-types.S": "SmartScore - Argent",
    "codes.smartscore-types.C": "SmartScore - Certifié",

    "codes.fitwel-types.F": "Fitwel",
    "codes.gresb-types.G": "GRESB",
    "codes.energystar-types.E": "ENERGY STAR",
    "codes.zerocarbon-types.Z": "ZERO CARBON",
    "codes.well-types.W": "WELL Health and Safety Rating",
    "codes.rhfac-types.R": "RHFAC",
    "codes.well2023-types.X": "WELL 2023",
    "codes.bomatoby-types.K": "BOMA TOBY",
    "codes.bomaexcellence-types.M": "BOMA EXCELLENCE",

    "codes.zoned-types.C": "Commercial",
    "codes.zoned-types.I": "Industriel",
    "codes.zoned-types.R": "Détail",
    "codes.zoned-types.E": "Résidentiel",
    "codes.zoned-types.N": "Institutionnel",
    "codes.zoned-types.O": "Autre locative",

    "codes.sale-or-lease-types.S": "Vendre",
    "codes.sale-or-lease-types.L": "Louer à bail",

    "codes.land-types.R": "Rural",
    "codes.land-types.U": "Urbain",
    "codes.land-types.C": "Récréatif",

    "codes.property-sub-types.C": "Commercial",
    "codes.property-sub-types.R": "Récréatif",
    "codes.property-sub-types.I": "Industriel",
    "codes.property-sub-types.E": "Détail",

    "codes.proposed-use-types.I": "Industriel",
    "codes.proposed-use-types.O": "Bureau",
    "codes.proposed-use-types.R": "Détail",
    "codes.proposed-use-types.E": "Résidentiel",
    "codes.proposed-use-types.C": "Récréatif",

    "codes.permitted-use-types.R": "Détail",
    "codes.permitted-use-types.I": "Industriel",
    "codes.permitted-use-types.E": "Résidentiel",
    "codes.permitted-use-types.C": "Commercial",
    "codes.permitted-use-types.U": "Rural",

    "codes.zoning-types.I": "Industriel",
    "codes.zoning-types.O": "Bureau",
    "codes.zoning-types.C": "Commercial",
    "codes.zoning-types.R": "Résidentiel",
    "codes.zoning-types.A": "Agricole",
    "codes.zoning-types.M": "Usage mixte",

    "codes.frontage-types.U": "Comté",
    "codes.frontage-types.C": "Ville",
    "codes.frontage-types.P": "Privé",

    "codes.surfacing-types.A": "Asphalte",
    "codes.surfacing-types.G": "Gravier",
    "codes.surfacing-types.C": "Concret",

    "codes.waterfront-types.L": "Lac",
    "codes.waterfront-types.O": "Océan",
    "codes.waterfront-types.R": "Rivière",
    "codes.waterfront-types.P": "Étang",

    "codes.class-types.3": "AAA",
    "codes.class-types.A": "A",
    "codes.class-types.B": "B",
    "codes.class-types.C": "C",

    "codes.interior-wall-types.D": "Placoplâtre",
    "codes.interior-wall-types.W": "Bois",
    "codes.interior-wall-types.S": "Pierre",
    "codes.interior-wall-types.B": "Brique",
    "codes.interior-wall-types.O": "Autres",

    "codes.lease-terms.F": "Flexible",
    "codes.lease-terms.L": "Moins d'un an",
    "codes.lease-terms.M": "Mensuel",
    "codes.lease-terms.Y": "Annuel",

    "codes.notice-period.N": "Aucun",
    "codes.notice-period.3": "30 Jours",
    "codes.notice-period.6": "60 Jours",
    "codes.notice-period.9": "90 Jours",
    "codes.notice-period.1": "120 Jours",

    "codes.tenant-rights.right_of_first_refusal": "Droit de premier refus",
    "codes.tenant-rights.right_of_first_offer": "Droit de première offre",
    "codes.tenant-rights.right_of_relocation": "Droit de réinstallation",
    "codes.tenant-rights.right_of_signage": "Droit de signalisation",
    "codes.tenant-rights.right_of_early_termination":
      "Droit de résiliation anticipée",
    "codes.tenant-rights.right_of_audit": "Droit de vérification",
    "codes.tenant-rights.right_of_exclusivity": "Droit d'exclusivité",
    "codes.tenant-rights.right_of_contraction": "Droit de contraction",
    "codes.tenant-rights.right_of_expansion": "Droit d'expansion",
    "codes.tenant-rights.right_of_renewal": "Droit de renouvellement",
    "codes.tenant-rights.right_of_gross_up": "Droit de gross up",

    "codes.mall-types.N": "Centre de quartier",
    "codes.mall-types.R": "Centre Régional",
    "codes.mall-types.S": "Super Centre Régional",
    "codes.mall-types.P": "Power Centre",
    "codes.mall-types.O": "Autres",

    "codes.measurement-units.I": "Imperial", // TODO
    "codes.measurement-units.M": "Metric", // TODO

    "codes.media-tags.T": "Thumbnail", // TODO
    "codes.media-tags.H": "Hero", // TODO
    "codes.media-tags.P": "Plan d'étage", // TODO
    "codes.media-tags.B": "Brochure", // TODO
    "codes.media-tags.SP": "Plan du site",

    "codes.suite-visibility-types.PB": "Publié",
    "codes.suite-visibility-types.PR": "Privé",

    // Form components
    "form.range-slider.min.label": "Min",
    "form.range-slider.max.label": "Max",
    "form.switch.off.label": "Arrêt",
    "form.switch.on.label": "Marche",
    "checkbox.select-all.label": "Tout sélectionner",
    "form.google-map.input.placeholder": "Entrez l’adresse de l’immeuble...",

    // Common App Messages
    "building-not-found-error-title":
      "Désolé, la propriété que vous recherchez est introuvable.",
    "building-not-found-error-content":
      "Le lien sur lequel vous avez cliqué peut être rompu ou la page peut avoir été supprimée.",
    "building-not-found-error-button": "Parcourir toutes les immeubles",

    // Common Validation Messages
    "common.form.validation.required": "Requis",
    "common.form.validation.map-location-required":
      "Please select an area on the map", // TODO
    "common.form.validation.password-rules":
      "Password must contain at least 1 special character from the list ! @ # $ % ^ & *, 1 number, 1 capital letter and have a minimum length of 10 characters", // TODO
    "common.form.validation.username-rules": "Username already exists", // TODO
    "form.validators.errors.notANumber": "N'est pas un nombre",
    "form.validators.errors.notAnInteger": "N'est pas un nombre entier",
    "common.alert.validation.alert-rules":
      "La session expirera dans {minutes}:{seconds}. Pour poursuivre la session, cliquez sur OK.",

    // Common Unit Labels
    "common.units.sq-ft": SQUARE_FEET_FRENCH,
    "common.units.sq-m": "sq. m.", // TODO
    "common.units.dollar": "$",
    "common.units.dollar-per-sq-ft": "${value}/pi²",
    "common.units.dollar-per-hour": "$/h",
    "common.units.dollar-per-day": "$/jour",
    "common.units.dollar-per-month": "$/mois",
    "common.units.num-per-sq-ft": "#/pi²",
    "common.units.voltage": "V",
    "common.units.amperage": "A",
    "common.units.watt": "W",
    "common.units.watt-per-sq-ft": "W/pi²",
    "common.units.percentage": "%",
    "common.units.acres": "acres",
    "common.units.dollar-per-acre": "$/acre",
    "common.units.feet": "Pi",
    "common.units.inches": "in.", // TODO
    "common.units.ft-in": "pi. po.",
    "common.units.years": "ans",
    "lang.formatted-unit.id": "{value, number}", // TODO

    // Common Button Labels
    "common.checkbox.toggle-all.select-all": "Tout sélectionner",
    "common.button.update.label": "Mettre à jour",
    "common.button.cancel.label": "Annuler",
    "common.button.edit.label": "Éditer",
    "common.button.delete.label": "Supprimer ",
    "common.button.save.label": "Sauvegarder",
    "common.button.reset.label": "Réinitialiser",
    "common.button.next.label": "Suivant",
    "common.button.filter.label": "Filtre",
    "common.button.yes.label": "Oui",
    "common.button.no.label": "Non",
    "common.button.ok.label": "OK",
    "common.button.add.label": "Ajouter",
    "common.button.transfer.label": "Transfert",
    "common.button.copy.label": "Copie",
    "common.button.add-user.label": "Ajouter un utilisateur",
    "common.button.transfer-buildings.label": "Transfert de bâtiments",
    "common.button.copy-buildings.label": "Copier des bâtiments",
    "common.button.create-user.label": "Créez un utilisateur",
    "common.button.add-buildings.label": "Ajouter des bâtiments",
    "common.button.upload.label": "Télécharger",
    "common.button.close.label": "Fermer",
    "common.button.new.label": "Nouveau",
    "common.button.add.new.tour.label": "Ajouter une Nouvelle Visite Virtuelle",
    "common.button.download.leads": "Télécharger les clients potentiels",

    // Login page
    "login.heading.greeting.label": "Démarrez avec CoRE.",
    "login.input.username.label": "Nom d'utilisateur",
    "login.input.password.label": "Mot de passe",
    "login.input.forgot-password.label": "Mot de passe oublié",
    "login.btn.login.label": "connexion",
    "login.btn.login-mri-id.label": "Connectez-vous avec l'ID client IRM",
    "login.btn.login-no-mri-id.label": "Se connecter sans ID client",
    "login.line.or.label": "Ou",
    "login.heading.recover-password.label": "Récupérer mot de passe",

    // Header page
    "header.search.placeholder": "Rechercher...",
    "header.user-context-menu.settings.label": "Paramètres",
    "header.user-context-menu.help-support.label": "Aide et soutien",
    "header.user-context-menu.logout.label": "Déconnexion",
    "header.language-menu.select-language.label": "Sélectionner une langue",
    "header.search.archivetag": "Archivé",
    // Left navigation
    "nav.icon.dashboard.helpertext": "Tableau de bord",
    "nav.icon.listings.helpertext": "Listes",
    "nav.icon.buildings.helpertext": "Bâtiments",
    "nav.icon.leasing-assistant.helpertext": "Assistant à la location",
    "nav.icon.settings.helpertext": "Paramètres",

    // Print PDF
    "print.stacking-plan.filter-criteria.label": "Filter Criteria", // TODO
    "print.stacking-plan.suite-type.label": "Types de suites",
    "print.stacking-plan.suite-availability-type.label":
      "Suite Availability Type", // TODO
    "print.stacking-plan.area-range.label": "Area Range", // TODO
    "print.stacking-plan.profile-lease-expiry.label": "Profile - Lease Expiry", // TODO
    "print.stacking-plan.profile-subtenancies.label": "Profile - Subtenancies", // TODO
    "print.stacking-plan.tenant-rights.label": "Droits des locataires",

    // Dashboard Page
    "dashboard.context-pane.header.page-title": "Tableau de bord",
    "dashboard.context-pane.tabs.marketing.label": "Marketing",
    "dashboard.context-pane.tabs.leasing.label": "Location",
    "dashboard.meta-header.description.engagement": "Engagement",
    "dashboard.meta-header.description": `Rapports pour ${numberWithPlaceholder(
      "totalBuildings",
      "{totalBuildings, plural, one {Building} other {Buildings}} (Possédé ou géré)"
    )}`,
    "dashboard.meta-header.stats.area-affected.number": numberWithPlaceholder(
      "areaAffected"
    ),
    "dashboard.meta-header.stats.total-suites.number": numberWithPlaceholder(
      "totalSuites"
    ),
    "dashboard.meta-header.stats.new-suites.number": numberWithPlaceholder(
      "newSuites"
    ),
    "dashboard.meta-header.stats.new-leases.number": numberWithPlaceholder(
      "newLeases"
    ),
    "dashboard.meta-header.stats.deleted-suites.number": numberWithPlaceholder(
      "deletedSuites"
    ),
    "dashboard.meta-header.stats.new-leads.number": numberWithPlaceholder(
      "newLeads"
    ),
    "dashboard.meta-header.stats.tours.number": numberWithPlaceholder("tours"),
    "dashboard.meta-header.stats.loi.number": numberWithPlaceholder("loi"),
    "dashboard.meta-header.stats.won.number": numberWithPlaceholder("won"),
    "dashboard.meta-header.stats.lost.number": numberWithPlaceholder("lost"),
    "dashboard.meta-header.stats.qualified.number": numberWithPlaceholder(
      "qualified"
    ),

    "dashboard.meta-header.stats.area-affected.label":
      "Superficie Totale (pi²)", //TODO
    "dashboard.meta-header.stats.suites-affected.label":
      "{totalSuites, plural, one {Local} other {Locaux}} attribués",
    "dashboard.meta-header.stats.new-suites.label":
      "Nouvelles {newSuites, plural, one {Suite} other {Suites}}",
    "dashboard.meta-header.stats.new-leases.label":
      "Nouveaux {newLeases, plural, one {Lease} other {Leases}}",
    "dashboard.meta-header.stats.deleted-suites.label":
      "Supprimé {deletedSuites, plural, one {Suite} other {Suites}}",
    "dashboard.meta-header.stats.new-leads.label":
      "{newLeads, plural, one {Client} other {Clients}} éventuels",
    "dashboard.meta-header.stats.qualified.label": "Répondent aux conditions", // TODO
    "dashboard.meta-header.stats.tours.label":
      "{tours, plural, one {Visite} other {Visites}}",
    "dashboard.meta-header.stats.loi.label": "Lettres d’intention", // TODO
    "dashboard.meta-header.stats.won.label": "Gagnés", // TODO
    "dashboard.meta-header.stats.lost.label": "Perdu", // TODO

    "dashboard.meta-header.stats.broker-network": "Réseau de courtiers",
    "dashboard.meta-header.stats.conversions":
      "Total des conversions marketing",
    "dashboard.meta-header.stats.total-page-views": "Total des pages vues",
    "dashboard.meta-header.stats.page-views-since": "depuis",
    "dashboard.meta-header.stats.conversions.tooltip":
      "Somme des clients potentiels qualifiés et des téléchargements de propriété",
    "dashboard.meta-header.stats.broker-network.tooltip":
      "Personnes distinctes ayant entré leur adresse de courriel sur le site Web",

    "dashboard.distribution-report.suite-area.number": "{area, number}",
    "dashboard.distribution-report.no-of-suites.number": "{suites, number}",
    "dashboard.distribution-report.total-area.number": "{totalArea, number}",
    "dashboard.distribution-report.total-suites.number":
      "{totalSuites, number}",
    "dashboard.distribution-report.total-rentable-area.number":
      "{totalRentableArea, number}",

    "dashboard.lease-expiry-profile.suite-area.number": "{area, number}",
    "dashboard.lease-expiry-profile.no-of-suites.number": "{suites, number}",
    "dashboard.lease-expiry-profile.total-area.number": "{totalArea, number}",
    "dashboard.lease-expiry-profile.total-suites.number":
      "{totalSuites, number}",
    "dashboard.lease-expiry-profile.total-rentable-area.number":
      "{totalSuiteArea, number}",

    "dashboard.report.suite-distribution.header.label":
      "DISTRIBUTION DES LOCAUX",
    "dashboard.report.lease-expiry-profile.header.label":
      "D'EXPIRATION DE BAIL",
    "dashboard.report.lease-expiry-profile.space-type.label": "Type", // TODO
    "dashboard.report.lease-expiry-profile.status.label": "Statut",
    "dashboard.report.lease-expiry-profile.suite-area.label":
      "Superficie (pi²)",
    "dashboard.report.lease-expiry-profile.no-of-suites.label": "Nb. de locaux",
    "dashboard.report.lease-expiry-profile.percentage-total.label": "% Total",
    "dashboard.report.lease-expiry-profile.report-table.label": "Total",
    "dashboard.report.lease-expiry-profile.total-rentable-area.label":
      "Superficie louable totale",
    "dashboard.report.insights.header.label": "PAGES VUES",
    "dashboard.report.engagement.header.label": "CONVERSIONS MARKETING",

    "dashboard.filter-panel.panel-header.building.label": "Portefeuille", // TODO
    "dashboard.filter-panel.section-header.fund.label": "Propriétaire",
    "dashboard.filter-panel.section-header.type.label": "Type",
    "dashboard.filter-panel.panel-header.location.label": "Emplacement",
    "dashboard.filter-panel.section-header.province-state.label":
      "Province/État",
    "dashboard.filter-panel.section-header.region.label": "Région",
    "dashboard.filter-panel.section-header.sub-region.label": "Sous-région",
    "dashboard.filter-panel.section-header.groups.label": "Groupes",
    "dashboard.filter-panel.section-header.city.label": "Ville",
    "dashboard.filter-panel.section-header.company.label":
      "Property Management", // TODO
    "dashboard.filter-panel.placeholder.company-select.label": "Select PM", // TODO
    "dashboard.filter-panel.placeholder.fund-select.label":
      "Sélectionner de fond",
    "dashboard.filter-panel.placeholder.province-state-select.label":
      "Sélectionner province/état",
    "dashboard.filter-panel.placeholder.region-select.label":
      "Sélectionner région",
    "dashboard.filter-panel.placeholder.sub-region-select.label":
      "Sélectionner Sous-région",
    "dashboard.filter-panel.placeholder.group-select.label":
      "Sélectionner groupes",
    "dashboard.redirect-banner.label":
      "Le nouveau tableau de bord du Marketing est maintenant disponible!",
    "dashboard.redirect-banner.description.label":
      "Obtenez des analyses en temps réel sur vos pistes qualifiées pour votre marketing, les meilleures annonces, le volume de trafic, et plus encore.",
    "dashboard.redirect-banner.btn.label": "Découvrez la nouvelle version",

    // Suite Listing View Page
    "listings.context-pane.header.page-title": "Listings",
    "listings.vs.label": "c.",

    "listings.meta-header.stats.published-area.label": "Superficie publiée",
    "listings.meta-header.stats.non-published-area.label":
      "Superficie non publiée",
    "listings.meta-header.stats.total-suite-area.label": "Surface disponsible",
    "listings.meta-header.stats.published-area": numberWithPlaceholder(
      "publishedArea",
      SQUARE_FEET_FRENCH
    ),
    "listings.meta-header.stats.non-published-area": numberWithPlaceholder(
      "nonPublishedArea",
      SQUARE_FEET_FRENCH
    ),
    "listings.meta-header.stats.total-suite-area": numberWithPlaceholder(
      "totalSuiteArea",
      SQUARE_FEET_FRENCH
    ),
    "listings.meta-header.stats.published-suites.number-label": numberWithPlaceholder(
      "publishedSuites",
      "{publishedSuites, plural, one {local} other {locaux}}"
    ),
    "listings.meta-header.stats.non-published-suites.number-label": numberWithPlaceholder(
      "nonPublishedSuites",
      "{nonPublishedSuites, plural, one {local} other {locaux}}"
    ),
    "listings.meta-header.stats.total-suites.number-label": numberWithPlaceholder(
      "totalSuites",
      "{totalSuites, plural, one {local} other {locaux}}"
    ),
    "listings.suite-list-view.table.header.suite-type": "Types de local",
    "listings.suite-list-view.table.header.suite": "Local",
    "listings.suite-list-view.table.header.type": "Type",
    "listings.suite-list-view.table.header.building": "Immeuble",
    "listings.suite-list-view.table.header.available-area":
      "Superficie disponible",
    "listings.suite-list-view.table.header.contig-div":
      "(Contigu. | Divisible)",
    "listings.suite-list-view.table.header.marketing-score":
      "Score de marketing",
    "listings.suite-list-view.table.header.online-activity":
      "Activité en ligne",
    "listings.suite-list-view.table.header.leads": "Clients potentiels",
    "listings.suite-list-view.table.header.dom": "Jours sur le marché",
    "listings.suite-list-view.table.header.status": "Statut",
    "listings.suite-list-view.table.available-area.number":
      "{availableArea, number}",
    "listings.suite-list-view.table.available-area-subtext.number":
      "({contiguousArea, number} | {minimumDivisibleArea, number})",

    "listings.filter-panel.section-header.suite-type.label": "Type de local",
    "listings.filter-panel.section-header.leasing-contact.label":
      "Coordonnées pour la location",
    "listings.filter-panel.section-header.city.label": "Ville",
    "listings.filter-panel.section-header.size.label": "Superficie pi²",

    // Buildings List View Page
    "buildings.context-pane.title.buildings.label": "Immeubles",
    "buildings.context-pane.button.new-building.label": "Nouvel immeuble",
    "buildings.context-pane.tabs.all-buildings.label": "Tous les immeubles",
    "buildings.context-pane.tabs.promoted.label": "Promu",

    "buildings.meta-header.stats.no-of-buildings.label": "Nb. d’immeubles",
    "buildings.meta-header.stats.promoted-buildings.label": "Immeuble promu",
    "buildings.meta-header.stats.no-of-groups.label": "Nb. de groupes",
    "buildings.meta-header.stats.types-of-buildings.label": "Types d'immeuble",
    "buildings.meta-header.stats.suite-count.number-label": numberWithPlaceholder(
      "suiteCount",
      "{suiteCount, plural, one {local} other {locaux}}"
    ),
    "buildings.meta-header.stats.suites-included.number-label": numberWithPlaceholder(
      "promotedSuitesCount",
      "{promotedSuitesCount, plural, one {local} other {locaux}} inclus"
    ),
    "buildings.meta-header.stats.grouped-buildings-included.number-label": numberWithPlaceholder(
      "groupedBuildingsCount",
      "{groupedBuildingsCount, plural, one {Immeuble} other {Immeubles}} inclus"
    ),
    "buildings.meta-header.stats.offices.label":
      "{officeBuildingCount, plural, one {Bureau} other {Bureau}}",
    "buildings.meta-header.stats.industrial.label": "Industriel",
    "buildings.meta-header.stats.retail.label": "Commerce de détail",
    "buildings.meta-header.stats.residential.label": "Résidentiel",
    "buildings.meta-header.stats.land.label": "Terrain",
    "buildings.meta-header.controls.sort-by.label": " Trier",
    "buildings.meta-header.controls.list-view.label": "Vue de liste",
    "buildings.meta-header.controls.grid-view.label": "Grille voir",

    "buildings.list-view.table.header.ranking": "Classement",
    "buildings.list-view.table.header.building": "Immeuble",
    "buildings.list-view.table.header.address": "Adresse",
    "buildings.list-view.table.header.no-of-floors": "Nb. d’étages",
    "buildings.list-view.table.header.type": "Type",
    "buildings.list-view.table.header.rentable-area": "Superficie locative",
    "buildings.list-view.table.header.listed-area": "Superficie à louer",
    "buildings.list-view.table.header.published": "Publié",

    "buildings.list-view.table.col.area.number": "{area, number} pi²",
    "buildings.list-view.table.col.available-area.number":
      "{availableArea, number} pi²",
    "buildings.list-view.table.col.suites.label":
      "{availableSuites, number} {availableSuites, plural, one {local} other {locaux}}",
    "buildings.published-toggle.private.confirmation-message":
      "Are you sure you want to make this building private ?", // TODO
    "buildings.published-toggle.public.confirmation-message":
      "Are you sure you want to make this building public ?", // TODO

    "buildings.grid-view.meta.floor-count.number":
      "{floorCount, number} {floorCount, plural, one {Floor} other {Floors}}",
    "buildings.grid-view.meta.area.number-label": "{area, number} pi²",
    "buildings.grid-view.meta.available-area.number-label":
      "{availableArea, number} pi² disponible",
    "buildings.grid-view.meta.total-suites.label":
      "{totalSuites, number} {totalSuites, plural, one {suite} other {suites}}",
    "buildings.grid-view.meta.available-suites.label":
      "{availableSuites, number} {availableSuites, plural, one {suite} other {suites}}",
    "buildings.grid-view.meta.available-suite-min-area.number-label":
      "{availableSuiteMinArea, number} pi²",
    "buildings.grid-view.meta.available-suite-max-area.number-label":
      "{availableSuiteMaxArea, number} pi²",

    "buildings.filter-panel.panel-header.building.label": "Bâtiment",
    "buildings.filter-panel.section-header.fund.label": "Propriétaire",
    "buildings.filter-panel.section-header.group.label": "Groupes",
    "buildings.filter-panel.panel-header.building-type.label":
      "Type d’immeuble",
    "buildings.filter-panel.panel-header.city.label": "Ville",
    "buildings.filter-panel.panel-header.size.label": "Superficie(pi²)",
    "buildings.filter-panel.panel-header.visibility.label": "Visibilité",
    "buildings.filter-panel.panel-header.favourites.label": "Favoris",
    "buildings.filter-panel.panel-header.archived.label": "Archivé",
    "buildings.filter-panel.section-header.company.label":
      "Gestion immobilière",
    "buildings.filter-panel.section-header.type.label": "Type",
    "buildings.filter-panel.placeholder.fund-select.label":
      "Sélectionner de fond",
    "buildings.filter-panel.placeholder.group-select.label": "Select Group", // TODO
    "buildings.filter-panel.placeholder.company-select.label": "Select PM", // TODO

    "buildings.building-menu.view-building.label": "Voir le bâtiment",
    "buildings.building-menu.group-building.label": "Bâtiment grouper",
    "buildings.building-menu.unpublish.label": "Dépublier",
    "buildings.building-menu.publish.label": "Publier",
    "buildings.building-menu.unpromote.label": "Dépromouvoir",
    "buildings.building-menu.promote.label": "Promouvoir",
    "buildings.building-menu.unarchive.label": "Désarchiver",
    "buildings.building-menu.archive.label": "Archiver",
    "buildings.building-menu.delete.label": "Supprimer",
    "buildings.building-menu.remove-group-building.label": "Enlever le groupe", // TODO
    "buildings.delete-group.confirmation-msg":
      "Êtes-vous sûr de vouloir supprimer le groupe de construction? -", // TODO

    "building.context-pane.tabs.stacking-plan.label": "Plan d'empilage",
    "building.context-pane.tabs.site-map.label": "Carte du site",
    "building.context-pane.tabs.rent-roll.label": "Registre des loyers",
    "building.context-pane.tabs.reports.label": "Rapports",
    "building.context-pane.tabs.information.label": "Information",
    "building.context-pane.tabs.specifications.label": "Spécifications",
    "building.context-pane.tabs.media.label": "Medias",
    "building.context-pane.tabs.contacts.label": "Contacts",
    "building.context-pane.tabs.leases.label": "Baux",

    "building.context-pane.print-stackng-plan.progress-message":
      "Generating PDF. This may take a few seconds.", // TODO

    "building.group-building.popup.title.label": "Bâtiment grouper",
    "building.group-building.popup.no-group-info":
      "Aucun groupe n'existe pour le moment. Créer une?",
    "building.group-building.popup.no-groups-info":
      "Aucun groupe n'existe pour le moment. Créer une?",
    "building.group-building.popup.usage-directions":
      "Ajouter un bâtiment à un groupe existant ou créer un nouveau groupe",
    // TODO: TRANSLATE
    "building.group-building.popup.create-group-usage-directions":
      "Entrez votre nom de groupe",
    "building.group-building.popup.button.add-to-selected-group.label":
      "Ajouter au groupe sélectionné",
    "building.group-building.popup.button.create-group.label":
      "Créer un groupe",
    // TODO: TRANSLATE
    "building.group-building.popup.button.submit.label": "Créer groupe",
    "building.group-building.popup.button.go-back.label": "Retourner",

    // Buildings - Promoted Buildings View
    "buildings.promoted.buildings.page.title": "À propos des immeubles promus",
    "buildings.promoted.buildings.page.info":
      "La promotion des immeubles est établie sur leur classement. Plus il est élevé, plus l’immeuble est promu. Pour reclasser les immeubles, vous n’avez qu’à cliquer et faire glisser l’immeuble de haut en bas dans le classement.",
    "buildings.promoted.buildings.table.no-buildings.promoted.info":
      "Aucun bâtiment promu",
    "buildings.promoted.buildings.table.dnd.drop.placeholder": "Déposer ici",

    // Buildings - Add New Building Wizard
    "building.add-new-wizard.header.page-title": "Créer un nouvel immeuble",
    "building.add-new-wizard.steps.building-type.label": "Type d'immeuble",
    "building.add-new-wizard.steps.information.label": "Informations",
    "building.add-new-wizard.steps.building-type.select-type.instruction":
      "Sélectionnez le type d'immeuble",
    "building.add-new-wizard.steps.information.form.building-name.label":
      "Nom de l'immeuble",
    "building.add-new-wizard.steps.information.form.no-of-floors.label":
      "Nombre d'étages",
    "building.add-new-wizard.steps.information.form.above.label": "AU DESSUS",
    "building.add-new-wizard.steps.information.form.ground.label":
      "rez-de-chaussée",
    "building.add-new-wizard.steps.information.form.below.label": "EN DESSOUS",
    "building.add-new-wizard.steps.information.form.own-building.label":
      "Êtes-vous propriétaire de cet immeuble?",
    "building.add-new-wizard.steps.information.form.manage-building.label":
      "Êtes-vous le gestionnaire de cet immeuble?", // TODO
    "building.add-new-wizard.steps.information.form.manage-building.own.label":
      "Posséder",
    "building.add-new-wizard.steps.information.form.manage-building.manage.label":
      "Gérer",
    "building.add-new-wizard.steps.information.form.address.label": "Adresse",
    "building.add-new-wizard.steps.information.form.city.label": "Ville",
    "building.add-new-wizard.steps.information.form.region.label": "Région",
    "building.add-new-wizard.steps.information.form.neighbourhood.label":
      "Quartier",
    "building.add-new-wizard.steps.information.form.sub-region.label":
      "Sous-région",
    "building.add-new-wizard.steps.information.form.province.label": "Province",
    "building.add-new-wizard.steps.information.form.country.label": "Pays",
    "building.add-new-wizard.steps.information.form.postal.label":
      "Code postal",
    "building.add-new-wizard.steps.information.form.localtion.label":
      "Emplacement",
    "building.add-new-wizard.steps.information.form.localtion.instructions":
      "Sélectionnez une zone sur la carte en effectuant une recherche. Vous pouvez également faire une recherche en utilisant les champs ci-dessous.",
    "building.add-new-wizard.steps.information.form.button.create-building.label":
      "Créer un Bâtiment",

    // Buildings - Leases List View Page
    "leases.filters.datepicker.from.label": "À Partir de",
    "leases.filters.datepicker.to.label": "À",
    "leases.filters.datepicker.input.placeholder": "Select date...", // TODO
    "leases.filters.suite-selector.input.placeholder": "Sélectionner...",
    "leases.header.new-lease.button.label": "Nouveau bail",
    "leases.header.expired-leases.button.label": "Afficher expiré",
    "leases.list-view.table.header.suite": "Suite",
    "leases.list-view.table.header.start-date": "Date de début",
    "leases.list-view.table.header.end-date": "Date de fin",
    "leases.list-view.table.header.tenant-name": "Nom du locataire",
    "leases.list-view.table.header.availability": "Disponibilité",
    "leases.list-view.table.header.area": "Superficie",
    "leases.list-view.table.header.sf": "(pi²)",
    "leases.list-view.table.header.rent": "Loyer",
    "leases.list-view.table.header.$sf": "($/pi²)",
    "leases.list-view.table.header.free-rent": "Loyer gratuit",
    "leases.list-view.table.header.mo": "(mois)",
    "leases.list-view.table.header.ti": "AL",
    "leases.list-view.table.header.lw": "LW",
    "leases.list-view.table.header.ext-lc": "EXT LC",
    "leases.list-view.table.header.mgmt-lc": "MGMT LC",
    "leases.list-view.table.header.incentive-total": "Totale incitative",
    "leases.list-view.table.area.number": "{area, number}",
    "leases.list-view.table.availability-option.month-to-month.label":
      "Mois à mois",
    "leases.list-view.table.availability-option.leased.label": "Loué ",

    "leases.filters.date-filter.range-validation.message":
      "Partir de la date ne peut pas être supérieur à la date de fin!",
    "lease.edit-lease.section.tenant-rights.title": "Droits du locataire",
    "leases.add-new-lease.dialog.title": "Créer un nouveau bail",
    "leases.add-new-lease.dialog.suite.label": "Suite",
    "leases.add-new-lease.dialog.suite.tenant-name": "Nom du locataire",
    "leases.add-new-lease.dialog.suite.start_date": "Date de début",
    "leases.add-new-lease.dialog.suite.end_date": "Date de fin",
    "leases.add-new-lease.dialog.suite.is_mo_to_mo": "Disponibilité",
    "leases.add-new-lease.dialog.suite.area": "Superficie", // TODO: Change to leased area
    "leases.add-new-lease.dialog.suite.rent": "Loyer",
    "leases.add-new-lease.dialog.suite.months_free_rent": "Loyer gratuit",
    "leases.add-new-lease.dialog.suite.tenant_inducement": "AL",
    "leases.add-new-lease.dialog.suite.landlord_work_cost": "LLW",
    "leases.add-new-lease.dialog.suite.external_leasing_cost": "EXT LC",
    "leases.add-new-lease.dialog.suite.internal_leasing_cost": "MGMT LC",
    "leases.add-new-lease.dialog.suite.incentive": "Totale incitative",
    "leases.add-new-lease.dialog.tenant-rights": "Droits des locataires",
    "leases.add-new-lease.dialog.create-success-msg": "Bail créé avec succès!",
    "leases.delete-lease.confirmation-dialog.title": "Confirmation",
    "leases.delete-lease.confirmation-msg":
      "En cliquant sur 'Oui', vous confirmez que vous souhaitez supprimer le bail pour",

    // Buildings - Stacking Plan View Page
    "stackingplan.floor.vacant-floor.title.label": "plancher vacant",
    "stackingplan.floor.vacant-floor.floor-identifier.placeholder":
      "Cliquez pour entrer l'identifiant du plancher",
    "stackingplan.floor.vacant-floor.floor-identifier.helptext":
      "Entrer l'identifiant du plancher",
    "stackingplan.floor.vacant-floor.floor-identifier.helptext-small":
      "Nom du plancher",
    "stackingplan.floor.vacant-floor.area.placeholder":
      "Cliquez pour entrer superficie",
    "stackingplan.floor.vacant-floor.area.helptext": "Entrez superficie",
    "stackingplan.floor.vacant-floor.area.helptext-small": "Superficie",
    "stackingplan.floor.vacant-floor.avl-area.label": "disponibilité inconnue",
    "stackingplan.floor.info.suite-count.number-label":
      "{suiteCount, number} {suiteCount, plural, one {suite is} other {suites} } à cet étage",
    "stackingplan.floor.info.expand-helptext.label":
      "Cliquez pour agrandir et voir les suites",
    // TODO: FLOORPLAN TRANSLATIONS
    "stackingplan.floor.floorplan.link.view-floor-plan": "Voir le plan",
    "stackingplan.floor.floorplan.link.create-floor-plan": "Créer un plan",
    "stackingplan.floor.floorplan.link.no-floor-plan": "Pas de plan",

    "stackingplan.floorplan.tooltip.commencement.label": "Commencement: ",
    "stackingplan.floorplan.tooltip.expiry.label": "Expiry: ",
    "stackingplan.floorplan.tooltip.base-rent.label": "Base Rent: $",

    "stackingplan.floor.floor-menu.add-suite": "Ajouter une suite",
    "stackingplan.floor.floor-menu.add-floor-above":
      "Ajouter un nouveau plancher au-dessus",
    "stackingplan.floor.floor-menu.add-floor-below":
      "Ajouter un nouveau plancher ci-dessous",
    "stackingplan.floor.floor-menu.delete-floor": "Supprimer le plancher",

    "stackingplan.suite.meta.suite-area.number-label":
      "{suiteArea, number} pi²",
    "stackingplan.suite.meta.exp.label": "Expiration",
    "stackingplan.suite.meta.avl-date.label": "Avl", //TODO
    "stackingplan.suite.meta.lease.start-date.label": "Date de début",
    "stackingplan.suite.meta.marketing-score.label": "Score marketing",
    "stackingplan.suite.meta.leased.label": "Loué",
    "stackingplan.suite.meta.committed.label": "Engagé", //TODO
    "stackingplan.suite.meta.vacant.label": "Vacant",
    "stackingplan.suite.meta.pending.label": "En ettente",
    "stackingplan.suite.meta.archived.label": "Archivé",
    "stackingplan.suite.meta.tenant-name.undisclosed.label": "Undisclosed", // TODO
    "stackingplan.suite.meta.avl-date.unknown": "Inconnue", //TODO

    "stackingplan.suite.suite-menu.split-suite": "Suite split",
    "stackingplan.suite.suite-menu.move-left": "À gauche",
    "stackingplan.suite.suite-menu.move-right": "À droite",
    "stackingplan.suite.suite-menu.archive-suite": "Archive Suite", // TODO
    "stackingplan.suite.suite-menu.unarchive-suite": "Unarchive Suite", // TODO
    "stackingplan.suite.suite-menu.delete-suite": "Supprimer la suite",
    "stackingplan.suite.suite-menu.add-suite-left":
      "Ajouter une nouvelle suite à gauche",
    "stackingplan.suite.suite-menu.add-suite-right":
      "Ajouter une nouvelle suite à droite",
    "stackingplan.suite.suite-menu.add-floor-above":
      "Ajouter une nouvelle étage au-dessus",
    "stackingplan.suite.suite-menu.add-floor-below":
      "Ajouter un nouveau plancher ci-dessous",
    "stackingplan.suite.suite-menu.delete-floor": "Supprimer le plancher",

    "stackingplan.suite.dnd.drop-here": "Déposer ici",

    "stackingplan.filter-panel.header.suite-filters.label": "Filtres de suite",
    "stackingplan.filter-panel.general-filters.label": "Filtres généraux",
    "stackingplan.filter-panel.subtenancies.label": "Sous-location",
    "stackingplan.filter-panel.marketed.label": "Commercialisé",
    "stackingplan.filter-panel.suite-search-filter.helpertext":
      "Search tenant or suite", //TODO
    "stackingplan.filter-panel.suite-type.helpertext":
      "Choisir types de suites",
    "stackingplan.filter-panel.suite-type.label": "Types de suites",
    "stackingplan.filter-panel.availability.label": "Disponibilité",
    "stackingplan.filter-panel.availability.helpertext":
      "Choisir disponibilité de type",
    "stackingplan.filter-panel.visibility.helpertext": "Select visibility type", // TODO
    "stackingplan.filter-panel.size.label": "Dimension (pi²)",
    "stackingplan.filter-panel.profile.label": "Profil",
    "stackingplan.filter-panel.office-space.label": "Espace de bureau",
    "stackingplan.filter-panel.basic-gross-rent.label":
      "Basique/Loyer brut ($/mo)",
    "stackingplan.filter-panel.tenant-rights.label": "Droits des locataires",
    "stackingplan.filter-panel.measurement-standards.label": "Norme de mesure",
    "stackingplan.filter-panel.retail-space.label": "Espace de vente",

    "stackingplan.filter-panel.select-tabs.lease-expiry.label": "Fin du bail",
    "stackingplan.filter-panel.select-tabs.tenant-rights.label":
      "Droits des locataires",
    "stackingplan.filter-panel.select-tabs.subtenancies.label": "Sous-location",
    "stackingplan.filter-panel.select-tabs.marketing.label": "Marketing",
    "stackingplan.filter-panel.select-tabs.committed-space.label":
      "Espace engagé",
    "stackingplan.filter-panel.select-tabs.lease-expiry.sq-footage.number-label":
      "{squareFootage, number} pi²",
    "stackingplan.filter-panel.select-tabs.lease-expiry.mo-to-mo.label":
      "Mois par mois",
    "stackingplan.filter-panel.select-tabs.lease-expiry.vacant.label": "Vacant",

    "stackingplan.filter-panel.suite-details.header.tabs.leases.label": "Baux",
    "stackingplan.filter-panel.suite-details.header.tabs.information.label":
      "Information",
    "stackingplan.filter-panel.suite-details.header.tabs.competitive-listing.label":
      "Liste compétitive",
    "stackingplan.filter-panel.suite-details.header.tabs.virtual-tour.label":
      "Tour Virtuel",
    "buildingspec.form-section.field.label.tour_url":
      "URL DE LA VISITE VIRTUELLE",
    "buildingspec.form-section.field.label.tour_name":
      "NOM DE LA VISITE VIRTUELLE",

    "stackingplan.filter-panel.suite-details.header.no-media.label":
      "Aucuns médias",
    //TODO: Translate
    "stackingplan.filter-panel.suite-details.header.add-suite-media.tooltip":
      "Cliquez pour ajouter le média à la suite {suiteNumber}.",

    "stackingplan.filter-panel.suite-details.information.tabs.marketing-attributes.label":
      "Attributs marketing",
    "stackingplan.filter-panel.suite-details.information.tabs.construction.label":
      "Construction",
    "stackingplan.filter-panel.suite-details.information.tabs.technical.label":
      "Techniques",
    "stackingplan.filter-panel.suite-details.information.tabs.parking.label":
      "Parking",
    "stackingplan.filter-panel.suite-details.information.tabs.other-attributes.label":
      "Autres attributs",

    "stackingplan.filter-panel.suite-details.button.add.label": "Ajouter",
    "stackingplan.filter-panel.suite-details.button.edit.label": "Éditer",
    "stackingplan.filter-panel.suite-details.lease.tenant-name":
      "Nom du locataire",
    "stackingplan.filter-panel.suite-details.lease.availability":
      "Disponibilité",
    "stackingplan.filter-panel.suite-details.lease.start-date": "Date de début",
    "stackingplan.filter-panel.suite-details.lease.end-date": "Date de fin",
    "stackingplan.filter-panel.suite-details.lease.expiry-date":
      "Date d'expiration",
    "stackingplan.filter-panel.suite-details.lease.area": "Superficie (pi²)",
    "stackingplan.filter-panel.suite-details.lease.no-data.label": "Aucun baux",
    "stackingplan.filter-panel.suite-details.lease.leased.label": "Loué",
    "stackingplan.filter-panel.suite-details.lease.leased-area.number-label":
      "{area, number} pi²",
    "stackingplan.filter-panel.suite-details.lease.rent": "Loyer ($/pi²)",
    "stackingplan.filter-panel.suite-details.lease.incentive": "Incitation",
    "stackingplan.filter-panel.suite-details.lease.external_leasing_cost":
      "Coût de location externe",
    "stackingplan.filter-panel.suite-details.lease.internal_leasing_cost":
      "Coût de location interne",
    "stackingplan.filter-panel.suite-details.lease.landlord_work_cost":
      "Coût de travail du propriétaire",
    "stackingplan.filter-panel.suite-details.lease.tenant_inducement":
      "Incitation du locataire",
    "stackingplan.filter-panel.suite-details.lease.tenant-rights":
      "Droits des locataires",

    "stackingplan.filter-panel.suite-details.information.broker-update.tooltip":
      "Les courtiers recevront une notification immédiate des changements apportés aux champs d'information surlignés.",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.panel.marketing-attributes.label":
      "Attributs marketing",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.panel.other-attributes.label":
      "Autres attributs",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.private.label":
      "Privée",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.public.label":
      "Publique",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.private-toggle.confirmation-msg":
      "Êtes-vous sûr de vouloir rendre cette suite privée?",
    "stackingplan.filter-panel.suite-details.information.marketing-attrs.public-toggle.confirmation-msg":
      "Êtes-vous sûr de vouloir rendre cette suite publique?",
    "stackingplan.filter-panel.suite-details.information.just-leased":
      "Nouvellement Loué",
    "stackingplan.filter-panel.suite-details.header.tabs.broker_update.label":
      "Inclure dans les mises à jour du courtier?",
    "stackingplan.filter-panel.suite-details.information.just-leased.tooltip":
      "Afficher une étiquette « Récemment loué » jusqu’à ce que la date soit remplie.\nNote : la modification de ce champ déclenchera une mise à jour pour le courtier.",
    "stackingplan.filter-panel.suite-details.information.new-suite":
      "Nouvelle Suite",
    "stackingplan.filter-panel.suite-details.information.model-suite":
      "Suite Modèle",
    "stackingplan.filter-panel.suite-details.information.promote-suite":
      "Suite Promue",
    "stackingplan.filter-panel.suite-details.information.import-id":
      "ID d'importation",
    "stackingplan.filter-panel.suite-details.information.marketing-area":
      "Marketing superficie",
    "stackingplan.filter-panel.suite-details.information.max-contiguous":
      "Espace contigu",
    "stackingplan.filter-panel.suite-details.information.min-divisible":
      "Divisible min",
    "stackingplan.filter-panel.suite-details.information.availability-date":
      "Date de disponibilité",
    "stackingplan.filter-panel.suite-details.information.notice-period":
      "Période de préavis",
    "stackingplan.filter-panel.suite-details.information.rent": "Loyer",
    "stackingplan.filter-panel.suite-details.information.additional-total":
      "Total additionnel",
    "stackingplan.filter-panel.suite-details.information.suite-notes":
      "Commentaires",
    "stackingplan.filter-panel.suite-details.information.suite_identifier":
      "Nom de la suite",
    "stackingplan.filter-panel.suite-details.information._type":
      "Type de suite",
    "stackingplan.filter-panel.suite-details.information.condition":
      "Condition de la suite",
    "stackingplan.filter-panel.suite-details.information.measured_area":
      "Superficie mesurée",
    "stackingplan.filter-panel.suite-details.information.boma_ansi":
      "BOMA/ANSI",

    // Buildings - Stacking Plan View Page - Suite Residential Extra Fields
    "stackingplan.filter-panel.suite-details.information.suiteresidential.residential_suite_type":
      "Type de suite résidentielle",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.residential_building_type":
      "Type d'immeuble résidentiel",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.washrooms":
      "Toilettes",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.rooms":
      "Chambres",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.appliances":
      "Appareils",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.cooling":
      "Refroidissement",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.heating":
      "Chauffage",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.high_speed_internet":
      "Internet haut débit",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.deposit":
      "Dépôt",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.utilities_included":
      "Charges comprises",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.kitchen_area":
      "Coin cuisine",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.dining_room_area":
      "Salle à manger",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.living_room_area":
      "Coin salon",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.master_bedroom_area":
      "Superficie de chambre principale",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.bedroom_two_area":
      "Superficie de chambre deux",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.bedroom_three_area":
      "Superficie de chambre trois",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.bedroom_four_area":
      "Superficie de chambre quatre",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.master_bathroom_area":
      "Superficie de salle de bain principale",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.bathroom_two_area":
      "Superficie de salle de bain deux",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.den_area":
      "Superficie de salon",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.balcony_patio":
      "Balcon, patio",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.storage_locker":
      "Meuble de rangement",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.parking_spot":
      "Place de parking",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.furnished":
      "Meublé",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.hardwood_floors":
      " Planchers de bois ",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.fireplace":
      "Cheminée",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.washer_dryer_hookup":
      "Raccordement de lave-linge séchant",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.cable_tv_ready":
      "Prêts pour câble",
    "stackingplan.filter-panel.suite-details.information.suiteresidential.satellite_tv_ready":
      "TV satellite prêt",
    "stackingplan.filter-panel.suite-details.information.placeholder":
      "Cliquer pour entrer une date d’expiration. ",
    // Buildings - Stacking Plan View Page - Suite Industrial Extra Fields
    "stackingplan.filter-panel.suite-details.suiteindustrial.office_space":
      "Espace de bureau",
    "stackingplan.filter-panel.suite-details.suiteindustrial.warehouse_space":
      "Superficie de l'entrepôt",
    "stackingplan.filter-panel.suite-details.suiteindustrial.clear_height":
      "Hauteur libre",
    "stackingplan.filter-panel.suite-details.suiteindustrial.clear_height_notes":
      "Á Propos de la hauteur libre",
    "stackingplan.filter-panel.suite-details.suiteindustrial.HVAC_notes":
      "Description du système CVC",
    "stackingplan.filter-panel.suite-details.suiteindustrial.lighting_notes":
      "Notes d'éclairage",
    "stackingplan.filter-panel.suite-details.suiteindustrial.available_voltage":
      "Tension disponible",
    "stackingplan.filter-panel.suite-details.suiteindustrial.available_amperage":
      "Ampérage disponible",
    "stackingplan.filter-panel.suite-details.suiteindustrial.electrical_notes":
      "Système électrique",
    "stackingplan.filter-panel.suite-details.suiteindustrial.sprinkler_system_notes":
      "Système de gicleurs ",
    "stackingplan.filter-panel.suite-details.suiteindustrial.shipping_doors_drive":
      "Portes de service au volant",
    "stackingplan.filter-panel.suite-details.suiteindustrial.shipping_doors_dock":
      "Portes de quai surélevées",
    "stackingplan.filter-panel.suite-details.suiteindustrial.shipping_doors_notes":
      "Quais de chargements",
    "stackingplan.filter-panel.suite-details.suiteindustrial.bay_width":
      "Largeur de la baie",
    "stackingplan.filter-panel.suite-details.suiteindustrial.bay_depth":
      "Profondeur de la baie",
    "stackingplan.filter-panel.suite-details.suiteindustrial.bay_notes":
      "Notes de baie",
    "stackingplan.filter-panel.suite-details.suiteindustrial.slab_notes":
      "Notes de la dalle",
    "stackingplan.filter-panel.suite-details.suiteindustrial.surface_stalls":
      "Stationnement extérieur",
    "stackingplan.filter-panel.suite-details.suiteindustrial.truck_trailer_parking":
      "Stationnement de remorque",
    "stackingplan.filter-panel.suite-details.suiteindustrial.surface_stall_ratio":
      "Ratio de place de stationnement ",
    "stackingplan.filter-panel.suite-details.suiteindustrial.parking_notes":
      "Notes de stationnement",

    // Buildings - Rent Roll View Page
    "rentroll.meta-header.stats.total-rentable-area.label":
      "Superficie locative totale",
    "rentroll.meta-header.stats.total-available-area.label":
      "Superficie totale disponible",
    "rentroll.meta-header.stats.total-not-available-area.label":
      "Superficie total non disponible",
    "rentroll.meta-header.stats.expires-less-1-year.label": "Expire < 1 an",
    "rentroll.meta-header.stats.mo-to-mo.label": " Du bail mensuel",
    "rentroll.meta-header.stats.committed.label": "Engagé",

    "rentroll.meta-header.stats.total-rentable-area":
      "{totalRentableArea, number} pi²",
    "rentroll.meta-header.stats.total-available-area":
      "{totalAvailableArea, number} pi²",
    "rentroll.meta-header.stats.total-not-available-area":
      "{totalNotAvailableArea, number} pi²",
    "rentroll.meta-header.stats.total-less-1-year":
      "{totalExpiresInLessThanOneYearArea, number} pi²",
    "rentroll.meta-header.stats.total-mo-to-mo-area":
      "{totalMonthToMonthArea, number} pi²",
    "rentroll.meta-header.stats.total-committed-area":
      "{totalCommittedArea, number} pi²",

    "rentroll.meta-header.stats.total-rentable-count.number-label":
      "{totalRentableCount, number} {totalRentableCount, plural, one {suite} other {suites}} (100%)",
    "rentroll.meta-header.stats.total-available-count.number-label":
      "{totalAvailableCount, number} {totalAvailableCount, plural, one {suite} other {suites}}",
    "rentroll.meta-header.stats.total-not-available-count.number-label":
      "{totalNotAvailableCount, number} {totalNotAvailableCount, plural, one {suite} other {suites}}",
    "rentroll.meta-header.stats.total-expires-less-1-year.number-label":
      "{totalExpiresInLessThanOneYearCount, number} {totalExpiresInLessThanOneYearCount, plural, one {suite} other {suites}}",
    "rentroll.meta-header.stats.total-mo-to-mo-count.number-label":
      "{totalMonthToMonthCount, number} {totalMonthToMonthCount, plural, one {suite} other {suites}}",
    "rentroll.meta-header.stats.total-committed-count.number-label":
      "{totalCommittedCount, number} {totalCommittedCount, plural, one {suite} other {suites}}",

    "rentroll.filter-panel.section-header.size.label": "Taille (pi²)",
    "rentroll.filter-panel.section-header.visibility.label": "Visibilité",

    "rentroll.bucket-list.header.suite.label": "Suite",
    "rentroll.bucket-list.header.floor.label": "l'Étage",
    "rentroll.bucket-list.header.type.label": "Type",
    "rentroll.bucket-list.header.tenant.label": "Locataire",
    "rentroll.bucket-list.header.area.label": "Superficie (pi²)",
    "rentroll.bucket-list.header.rent.label": "Loyer ($/pi²)",
    "rentroll.bucket-list.header.from-date.label": "Partir de la date",
    "rentroll.bucket-list.header.to-date.label": "À ce jour",

    "rentroll.bucket-list.bucket.header.square-footage.number-label":
      "{squareFootage, number} pi²",
    "rentroll.bucket-list.bucket.suit-area.number-label":
      "{suiteArea, number} pi²",
    "rentroll.bucket-list.bucket.lease-rent.units-label":
      "{leaseRent, number}$/pi²",
    "rentroll.bucket-list.bucket.available-immediately.label":
      "Disponibilité immédiatement",
    "rentroll.bucket-list.bucket.no-leases-msg.label":
      "Il n'y a pas de bail expirant dans {bucketName}",
    "rentroll.bucket-list.bucket.vacant.no-leases-msg.label":
      "Aucune suite vacante",
    "rentroll.bucket-list.bucket.mo-to-mo.no-leases-msg.label":
      "Pas de mois en mois",

    // Buildings - Building Reports
    "buildingreports.activity-feed-panel.title.label": "ACTIVITÉ EN LIGNE",
    "buildingreports.occupancy-report.title.label": "OCCUPATION",
    "buildingreports.occupancy-report.graph.plot.value":
      "{vacantArea, number} pi² ({vacantPercentage}%)",
    "buildingreports.occupancy-report.legend.occupied": "OCCUPÉ",
    "buildingreports.occupancy-report.legend.vacant": "VACANT",
    "buildingreports.occupancy-report.stats-table.floor.label": "Étage",
    "buildingreports.occupancy-report.stats-table.total-suites.label":
      "Nombre total de suites",
    "buildingreports.occupancy-report.stats-table.no-vacant.label":
      "Nombre de vacants",
    "buildingreports.occupancy-report.stats-table.prev-period.label":
      "Préc. période",
    "buildingreports.occupancy-report.stats-table.no-vacant.number-label":
      "{vacantSuites, number} ({vacantPercentage}%)",
    "buildingreports.occupancy-report.stats-table.prev-period.number-label":
      "{prevVacantSuites, number} ({prevVacantPercentage}%)",

    "buildingreports.occupancy-report.y-label": "Vacant Space", // TODO
    "buildingreports.suite-distribution-report.title.label":
      "DISTRIBUTION DE SUITE",
    "buildingreports.distribution-report.stats-table.space-type.label":
      "Type d'espace",
    "buildingreports.distribution-report.stats-table.suite-area.label":
      "Suite superficie (pi²)",
    "buildingreports.distribution-report.stats-table.no-of-suites.label":
      "Nombre de suites",
    "buildingreports.distribution-report.stats-table.percentage-total.label":
      "% Total",
    "buildingreports.distribution-report.stats-table.area.number":
      "{area, number}",
    "buildingreports.distribution-report.stats-table.total-area.number":
      "{totalArea, number}",
    "buildingreports.distribution-report.stats-table.total-suite-area.number":
      "{totalSuiteArea, number}",
    "buildingreports.distribution-report.stats-table.report-total.label":
      "Rapport total",
    "buildingreports.distribution-report.stats-table.total-suite-area.label":
      "Superficie louable totale",

    "buildingreports.lease-expiry-report.title.label":
      "PROFIL D'EXPIRATION DU BAIL",
    //TODO: Translate status.label
    "buildingreports.lease-expiry-report.status.label": "Statut",

    "buildingreports.largest-tenant-report.title.label":
      "LE PLUS GRAND LOCATAIRE",
    "buildingreports.largest-tenant-report.stats-table.tenant.label":
      "Locataire",

    "buildingreports.vacant-suites-report.title.label":
      "NOMBRE DE SUITES VACANTES",
    "buildingreports.vacant-suites.stats-table.suite.label": "Suite",
    "buildingreports.vacant-suites.stats-table.views.label": "Vues",
    "buildingreports.vacant-suites.stats-table.no-of-months-vacant.label":
      "Nombre de mois vacants",
    "buildingreports.vacant-suites.stats-table.marketing-score.label":
      "Score marketing",

    // Buildings - Building Information Page
    "buildinginfo.meta-header.stats.no-of-floors.label": "Nb. d'étages",
    "buildinginfo.meta-header.stats.no-of-suites.label": "Nb. des suites",
    "buildinginfo.meta-header.stats.no-of-leases.label": "Nb. des baux",
    "buildinginfo.meta-header.stats.rba.label": "RBA",
    "buildinginfo.meta-header.stats.occupancy.label": "Occupation",
    "buildinginfo.meta-header.stats.avg-base-rent.label": "Loyer de base moyen",
    "buildinginfo.meta-header.stats.walt.label":
      "Durée moyenne pondérée des baux ",
    "buildinginfo.meta-header.stats.18-mo-l-roll.label": "18 mo. L. roll",
    "buildinginfo.meta-header.stats.total-available.label": "Total disponible",

    "buildinginfo.meta-header.stats.no-of-floors.number": "{floors, number}",
    "buildinginfo.meta-header.stats.no-of-suites.number": "{suites, number}",
    "buildinginfo.meta-header.stats.no-of-leases.number": "{leases, number}",
    "buildinginfo.meta-header.stats.rba.number": "{rba, number}",
    "buildinginfo.meta-header.stats.occupancy.number": "{occupancy, number}%",
    "buildinginfo.meta-header.stats.walt.number": "{walt, number}",
    "buildinginfo.meta-header.stats.18-mo-l-roll.number":
      "{eighteenMoRoll, number}%",
    "buildinginfo.meta-header.stats.total-available.number":
      "{available, number}%",
    "buildinginfo.meta-header.stats.occupancy-sqft.number-label":
      "{occupancy_sqft, number} pi²",
    "buildinginfo.meta-header.stats.per-month.label": "par mois",
    "buildinginfo.meta-header.stats.18mo-roll-sqft.number-label":
      "{eighteenMoRollSqft, number} pi²",
    "buildinginfo.meta-header.stats.available-sqft.number-label":
      "{available_sqft, number} pi²",

    "buildinginfo.dialog.title.label": "Titre",
    "buildinginfo.dialog.content.label": "Content", // TODO
    "buildinginfo.heading.button.new-heading.label": "Nouvelle rubrique",
    "buildinginfo.heading.popup.title.new-heading.label": "Nouvelle rubrique",
    "buildinginfo.info-section.description.title": "BUILDING DESCRIPTION", // TODO
    "buildinginfo.info-section.features.title": "FEATURES", // TODO
    "buildinginfo.info-section.amenities.title": "AMENITIES", // TODO
    "buildinginfo.info-section.sustainability.title": "SUSTAINABILITY", // TODO
    "buildinginfo.info-section.location.title": "Localisation",
    "buildinginfo.info-section.content.empty-editor.placeholder":
      "Entrez le contenu ici...",

    "buildinginfo.announcements.popup.title.new-announcement.label":
      "Nouvelle annonce",
    "buildinginfo.announcements.panel.title.marketing-announcements.label":
      "Annonces marketing",
    "buildinginfo.announcements.panel.title.marketing-announcements.sent.label":
      "Envoyé",
    "buildinginfo.announcements.panel.title.marketing-announcements.send-updates.label":
      "envoyer la mise à jour", //TODO
    "buildinginfo.info-section.content.date-editor.placeholder":
      "Cliquer pour entrer une date d’expiration. ",

    "buildinginfo.location-form.building-name.label": "Nom du bâtiment",
    "buildinginfo.location-form.street.label": "Rue",
    "buildinginfo.location-form.address.label": "Adresse",
    "buildinginfo.location-form.region.label": "Région",
    "buildinginfo.location-form.neighbourhood.label": "Quartier",
    "buildinginfo.location-form.city.label": "Ville",
    "buildinginfo.location-form.sub-region.label": "Sous-région",
    "buildinginfo.location-form.province-state.label": "Province/état",
    "buildinginfo.location-form.node.label": "Nœud",
    "buildinginfo.location-form.postal-zip.label": "Code postal",
    "buildinginfo.location-form.group.label": "Groupe",
    "buildinginfo.location-form.country.label": "Pays",
    "buildinginfo.location-form.button.reset.label": "Réinitialiser",
    "buildinginfo.location-form.button.save.label": "Sauvegarder",
    "buildinginfo.building-info-section.dnd.drop.placeholder": "Déposer ici",

    // Buildings - Building Specifications Page
    "buildingspec.form-section.title.building_info":
      "INFORMATION SUR LE BÂTIMENT",
    "buildingspec.form-section.title.general_information": "GÉNÉRALES",
    "buildingspec.form-section.title.certifications": "CERTIFICATIONS",
    "buildingspec.form-section.title.tenant_costs": "COÛTS DES LOCATAIRES",
    "buildingspec.form-section.title.size_information":
      "INFORMATION SUR LA SUPERFICIE",
    "buildingspec.form-section.title.construction_information":
      " INFORMATIONS SUR LA CONSTRUCTION",
    "buildingspec.form-section.title.elevators": "INFORMATIONS SUR L'ASCENSEUR",
    "buildingspec.form-section.title.safety_and_access_information":
      "SÉCURITÉ ET ACCÈS",
    "buildingspec.form-section.title.parking_information":
      "INFORMATION DE STATIONNEMENT",
    "buildingspec.form-section.title.building_virtual_tours": "TOUR VIRTUEL",
    "buildingspec.form-section.title.anchor_tenants": "LOCATAIRE-CLÉS",
    "buildingspec.form-section.title.public_transit_information":
      "TRANSPORT EN COMMUN",
    "buildingspec.form-section.title.site_description": "DESCRIPTION DU SITE",
    "buildingspec.form-section.title.technical_information":
      "INFORMATION TECHNIQUES",
    "buildingspec.form-section.title.land_information": "INFORMATION FONCIÈRE",
    "buildingspec.form-section.title.serviced_information":
      "INFORMATIONS SERVICIÉES",
    "buildingspec.form-section.title.yearly_realty_taxes":
      "TAXES D'IMMOBILISATION ANNUELLE",
    "buildingspec.form-section.title.amenities": "COMMODITÉS",
    "buildingspec.form-section.title.services": "SERVICES",
    "buildingspec.form-section.title.retail_profile": "PROFIL DE DÉTAIL",
    "buildingspec.form-section.title.operating_horus": "HEURES D'OUVERTURE",
    "buildingspec.form-section.title.demographics": "DONNÉES DÉMOGRAPHIQUES",

    // Industrial Building Type
    "buildingspec.form-section.field.label.name": "Nom de bâtiment",
    "buildingspec.form-section.field.label.group": "Groupe",
    "buildingspec.form-section.field.label.fund": "Propriétaire",
    "buildingspec.form-section.field.label.region": "Région",
    "buildingspec.form-section.field.label.sub_region": "Sous-région",
    "buildingspec.form-section.field.label._type": "Type d'immeuble",
    "buildingspec.form-section.field.label.is_owned_building":
      "Bâtiment appartient ?",
    "buildingspec.form-section.field.label.is_managed": "Bâtiment géré ?",
    "buildingspec.form-section.field.label.import_id": "ID d'importation",
    "buildingspec.form-section.field.label.units": "Measurement Units", // TODO
    "buildingspec.form-section.field.label.exterior_finish": "Fini extérieur",
    "buildingspec.form-section.field.label.roofing_type": "Type de toit",
    "buildingspec.form-section.field.label.available_voltage":
      "Tension disponible",
    "buildingspec.form-section.field.label.heating_description":
      "Description du chauffage",
    "buildingspec.form-section.field.label.power_description":
      "Description du énergie",
    "buildingspec.form-section.field.label.available_amperage":
      "Ampérage disponible",
    "buildingspec.form-section.field.label.new_development":
      "Nouveau développement ?",
    "buildingspec.form-section.field.label.year_built": "Construit (année)",
    "buildingspec.form-section.field.label.year_last_renovated":
      "Dernière rénovation (année)",
    "buildingspec.form-section.field.label.boma_best": "Meilleur BOMA",
    "buildingspec.form-section.field.label.certificates": "Certifications",
    "buildingspec.form-section.field.label.leed": "LEED",
    "buildingspec.form-section.field.label.building_management":
      "Nom du gestionnaire",
    "buildingspec.form-section.field.label.building_website": "Site web",
    "buildingspec.form-section.field.label.building_zoned": "Zonage",
    "buildingspec.form-section.field.label.surface_stalls":
      "Espaces extérieurs",
    "buildingspec.form-section.field.label.above_ground_stalls":
      "Espaces surélevés",
    "buildingspec.form-section.field.label.below_ground_stalls":
      "Espaces souterrains",
      "buildingspec.form-section.field.label.trailer_parking_stalls":
      "Espaces remorques",  
    "buildingspec.form-section.field.label.total_stalls":
      " Total de Espaces de stationnement",
    "buildingspec.form-section.field.label.parking_description":
      "Description du stationnement",
    "buildingspec.form-section.field.label.fire_detection_system":
      "Système de détection d'incendie ?",
    "buildingspec.form-section.field.label.sprinkler_system":
      "Système de gicleurs ?",
    "buildingspec.form-section.field.label.security_system":
      "Système de sécurité ?",
    "buildingspec.form-section.field.label.manned_security":
      "Gardes de sécurité ?",
    "buildingspec.form-section.field.label.safety_and_access_description":
      "Sécurité et accès description",
    "buildingspec.form-section.field.label.average_office_percentage_area":
      "Espace bureau % moyen",
    "buildingspec.form-section.field.label.number_of_buildings":
      "Nombre de bâtiments",
    "buildingspec.form-section.field.label.number_of_units": "Nombre d'unités",
    "buildingspec.form-section.field.label.primary_usage":
      "Utilisation primaire",
    "buildingspec.form-section.field.label.secondary_usage":
      "Utilisation secondaire",
    "buildingspec.form-section.field.label.site_zoning_description":
      "Zonage description",
    "buildingspec.form-section.field.label.site_coverage": "Couverture du site",
    "buildingspec.form-section.field.label.total_acreage":
      "Grandeur totale (acres)",
    "buildingspec.form-section.field.label.total_industrial_space":
      "Superficie totale de l'espace industriel",
    "buildingspec.form-section.field.label.available_industrial_space":
      "Espace industriel disponible",
    "buildingspec.form-section.field.label.total_office_space":
      "Superficie totale de l'espace de bureau",
    "buildingspec.form-section.field.label.available_office_space":
      "Espace de bureau disponible",
    "buildingspec.form-section.field.label.total_other_space":
      "Superficie Total des autres espaces",
    "buildingspec.form-section.field.label.available_other_space":
      "Espace autre disponible",
    "buildingspec.form-section.field.label.total_space": "Espace total",
    "buildingspec.form-section.field.label.total_available_space":
      "Espace total disponible",
    "buildingspec.form-section.field.label.compute_available_space":
      "Calculez les zones disponibles du plan d'empilage ?",
    "buildingspec.form-section.field.label.occupied_space": "Espace occupé",
    "buildingspec.form-section.field.label.manufacturing_size":
      "Grandeur de la manufacture",
    "buildingspec.form-section.field.label.warehouse_size":
      "Grandeur de l'entrepôt",
    "buildingspec.form-section.field.label.ceiling_height": "Hauteur libre",
    "buildingspec.form-section.field.label.max_door_height":
      "Hauteur maximum des portes",
    "buildingspec.form-section.field.label.shipping_doors_drive_in":
      "Nombre de portes de service au volant",
    "buildingspec.form-section.field.label.shipping_doors_dock":
      "Nombre de portes de quai surélevées",
    "buildingspec.form-section.field.label.marshalling_area":
      "Aire de rassemblement",
    "buildingspec.form-section.field.label.dolly_pad": "Aire de béquillage",
    "buildingspec.form-section.field.label.storage_description":
      "Description de l'entrepôt",
    "buildingspec.form-section.field.label.rail_loading":
      "Rail de chargement ?",
    "buildingspec.form-section.field.label.outside_storage":
      "Entreposage extérieur ?",
    "buildingspec.form-section.field.label.building_plan":
      "Plan de l'immeuble ?",
    "buildingspec.form-section.field.label.peer_review":
      "Examen par les pairs ?",
    "buildingspec.form-section.field.label.survey": "Arpentage  ?",
    "buildingspec.form-section.field.label.dry_cleaning_solvents":
      "Solvants de nettoyage à sec ?",
    "buildingspec.form-section.field.label.asbestos_free": "Sans amiante ?",
    "buildingspec.form-section.field.label.net_rent": "Loyer net",
    "buildingspec.form-section.field.label.utilities": "Énergie",
    "buildingspec.form-section.field.label.operating_costs":
      "Coûts d'exploitation",
    "buildingspec.form-section.field.label.realty_tax": "Impôts fonciers",
    "buildingspec.form-section.field.label.other_tenant_costs":
      "Autres frais de locataire",
    "buildingspec.form-section.field.label.total_additional_rent":
      "Total supplémentaire de loyer (exclut le loyer net)",
    "buildingspec.form-section.field.label.tenant_costs_description":
      "Frais locatifs description",

    // Land Building Type
    "buildingspec.form-section.field.label.sale_or_lease":
      "de Vente ou du bail ?",
    "buildingspec.form-section.field.label.land_type": "Type de terre",
    "buildingspec.form-section.field.label.property_sub_type":
      "Sous-propriété type",
    "buildingspec.form-section.field.label.proposed_use":
      "Utilisation proposée",
    "buildingspec.form-section.field.label.zoning": "Zonage",
    "buildingspec.form-section.field.label.number_of_lots": "Nombre de lots",
    "buildingspec.form-section.field.label.road_frontage": "Façade de la route",
    "buildingspec.form-section.field.label.road_surface": "Revêtement routier",
    "buildingspec.form-section.field.label.waterfront": "Bord de l'eau",
    "buildingspec.form-section.field.label.permitted_uses":
      "l'Utilisation permise",
    "buildingspec.form-section.field.label.sale_price_total":
      "Prix de vente total",
    "buildingspec.form-section.field.label.sale_price_per_acre":
      "Prix de vente",
    "buildingspec.form-section.field.label.lease_rate_per_acre":
      "Taux de location",
    "buildingspec.form-section.field.label.land_area_sqft":
      "Superficie territoriale",
    "buildingspec.form-section.field.label.land_area_acres":
      "Superficie territoriale",
    "buildingspec.form-section.field.label.building_area_sqft":
      "Aire de bâtiment",
    "buildingspec.form-section.field.label.building_area_acres":
      "Aire de bâtiment",
    "buildingspec.form-section.field.label.zoning_description": "Zonage",
    "buildingspec.form-section.field.label.water_city": "Eau municipale ?",
    "buildingspec.form-section.field.label.water_well": "Eau de puits ?",
    "buildingspec.form-section.field.label.sewer_septic": "Égout - septique ?",
    "buildingspec.form-section.field.label.sewer_municipal":
      "Égout - municipale ?",
    "buildingspec.form-section.field.label.electrical": "Électrique ?",
    "buildingspec.form-section.field.label.natural_gas": "Gaz naturel ?",
    "buildingspec.form-section.field.label.fibre_optic": "Fibre optique ?",
    "buildingspec.form-section.field.label.satellite": "Satellite ?",
    "buildingspec.form-section.field.label.internet": "Internet ?",
    "buildingspec.form-section.field.label.telephone": "Téléphone ?",
    "buildingspec.form-section.field.label.transit_highway":
      "Description transit / autoroute ",
    "buildingspec.form-section.field.label.service_description":
      "Description de service",
    "buildingspec.form-section.field.label.location": "Lieu ?",
    "buildingspec.form-section.field.label.traffic_count":
      "Comptage du trafic ?",

    // Office Building Type
    "buildingspec.form-section.field.label.anchor_tenant_one":
      "Locataire-clé un",
    "buildingspec.form-section.field.label.anchor_tenant_two":
      "Locataire-clé deux",
    "buildingspec.form-section.field.label.anchor_tenant_three":
      "Locataire-clé trois",
    "buildingspec.form-section.field.label.anchor_tenant_four":
      "Locataire-clé quatre",
    "buildingspec.form-section.field.label.anchor_tenant_five":
      "Locataire-clé cinq",
    "buildingspec.form-section.field.label.anchor_tenant_six":
      "Locataire-clé six",
    "buildingspec.form-section.field.label.typical_power": "Électrique moyenne",
    "buildingspec.form-section.field.label.hvac_after_hours_cost":
      "Système CVC après les heures normales",
    "buildingspec.form-section.field.label.lighting": "Éclairage",
    "buildingspec.form-section.field.label.hvac_hours_of_operation":
      "Heures de fonctionnement du système CVC",
    "buildingspec.form-section.field.label.interior_wall_type":
      "Type de mur intérieur",
    "buildingspec.form-section.field.label.hvac_distribution_system_description":
      "Description du système CVC",
    "buildingspec.form-section.field.label.washrooms_per_floor":
      "Salles de bain par étage ",
    "buildingspec.form-section.field.label.satellite_dish_capable":
      "Possibilité d'antennes satellites ?",
    "buildingspec.form-section.field.label.fibre_optic_capable":
      "Possibilité de fibre optique ?",
    "buildingspec.form-section.field.label.shipping_receiving":
      "Expédition/Réception ?",
    "buildingspec.form-section.field.label.emergency_generator":
      "Générateur d'urgence ?",
    "buildingspec.form-section.field.label.number_of_high_rise":
      "Nombre d'ascenseurs de grande hauteur",
    "buildingspec.form-section.field.label.number_of_parking":
      "Nombre d'ascenseurs de stationnement",
    "buildingspec.form-section.field.label.number_of_mid_rise":
      "Nombre d'ascenseurs de moyenne hauteur",
    "buildingspec.form-section.field.label.number_of_freight":
      "Nombre d'ascenseurs de monte-charge",
    "buildingspec.form-section.field.label.number_of_low_rise":
      "Nombre d'ascenseurs de faible hauteur",
    "buildingspec.form-section.field.label.building_class":
      "Classe de bâtiment",
    "buildingspec.form-section.field.label.surface_stall_ratio":
      "Ratio de stationnement extérieurs",
    "buildingspec.form-section.field.label.above_ground_ratio":
      "Ratio au dessus du sol",
    "buildingspec.form-section.field.label.below_ground_ratio":
      "Ratio au sous-sol",
    "buildingspec.form-section.field.label.parking_cost_per_day":
      "Coût de stationnement par jour",
    "buildingspec.form-section.field.label.parking_cost_per_month":
      "Coût de stationnement par mois",
    "buildingspec.form-section.field.label.surface_transit_route":
      "Route de transit de surface ?",
    "buildingspec.form-section.field.label.direct_subway_access":
      "Accès direct au métro  ?",
    "buildingspec.form-section.field.label.public_transit_description":
      "Description du transport en commun",
    "buildingspec.form-section.field.label.barrier_free_access":
      "Accès sans obstacles ?",
    "buildingspec.form-section.field.label.number_of_floors": "Nb d'étages",
    "buildingspec.form-section.field.label.typical_high_rise_floor":
      "Étage en hauteur type",
    "buildingspec.form-section.field.label.typical_low_rise_floor":
      "Étage en mi-hauteur type",
    "buildingspec.form-section.field.label.typical_high_rise_gross_up":
      "Étage en hauteur type brut",
    "buildingspec.form-section.field.label.typical_low_rise_gross_up":
      "Étage en mi-hauteur type brut",
    "buildingspec.form-section.field.label.typical_unit_size":
      "Superficie d'unité typique",
    "buildingspec.form-section.field.label.total_retail_space":
      "Superficie totale de l'espace commercial",
    "buildingspec.form-section.field.label.available_retail_space":
      "Espace commercial disponible",
    "buildingspec.form-section.field.label.total_occupied_space":
      "Total des espaces occupés",
    "buildingspec.form-section.field.label.largest_contiguous_available":
      "Plus grand espace contigu disponible",

    // Resident Building Type
    "buildingspec.form-section.field.label.onsite_superintendent":
      "Surintendant sur place?",
    "buildingspec.form-section.field.label.fitness_centre":
      "Centre de fitness ?",
    "buildingspec.form-section.field.label.swimming_pool": "Piscine ?",
    "buildingspec.form-section.field.label.multipurpose_room":
      "salle polyvalente ?",
    "buildingspec.form-section.field.label.locker_and_bike_storage":
      "Casier et stockage de vélo ?",
    "buildingspec.form-section.field.label.ac_in_unit": "AC dans l'unité ?",
    "buildingspec.form-section.field.label.pets": "animaux acceptés ?",
    "buildingspec.form-section.field.label.recycling": "Recyclage ?",
    "buildingspec.form-section.field.label.picnic_area": "Aire de picnic ?",
    "buildingspec.form-section.field.label.playground": "Terrain de jeux ?",
    "buildingspec.form-section.field.label.laundry_in_unit":
      "Salle de buanderie - dans l'unité ?",
    "buildingspec.form-section.field.label.laundry_shared":
      "Buanderie communes ?",
    "buildingspec.form-section.field.label.laundry_description":
      "Description - buanderie",
    "buildingspec.form-section.field.label.rooftop_deck":
      "Terrasse sur le toit ?",
    "buildingspec.form-section.field.label.business_centre":
      "Centre d'affaires ?",
    "buildingspec.form-section.field.label.bike_rack": "Porte-vélo ?",
    "buildingspec.form-section.field.label.barbecue": "Barbecue ?",
    "buildingspec.form-section.field.label.sauna": "Sauna ?",
    "buildingspec.form-section.field.label.pet_area":
      "Aire des animaux de compagnie ?",
    "buildingspec.form-section.field.label.pet_washing_station":
      "Station de lavage pour animaux ?",
    "buildingspec.form-section.field.label.games_room": "Salle de jeu ?",
    "buildingspec.form-section.field.label.building_type": "Type d'immeuble",
    "buildingspec.form-section.field.label.residential_building_type":
      "Type d'immeuble résidentiel",
    "buildingspec.form-section.field.label.visitor_parking_stalls":
      "Nombre de stationnement pour visiteurs",
    "buildingspec.form-section.field.label.cost_per_month": "Coût mensuel",
    "buildingspec.form-section.field.label.controlled_access":
      "Accès contrôlé ?",
    "buildingspec.form-section.field.label.intercom": "Interphone ?",
    "buildingspec.form-section.field.label.gated_entry": "Entrée fermée ?",
    "buildingspec.form-section.field.label.twenty_four_hour_maintenance":
      "Entretien 24 H ",
    "buildingspec.form-section.field.label.assisted_living": "Vie assistée ?",
    "buildingspec.form-section.field.label.online_rent_payment":
      "Paiement de loyer en ligne",
    "buildingspec.form-section.field.label.online_maintenance_portal":
      "Portail de maintenance en ligne",
    "buildingspec.form-section.field.label.units_in_building_bachelor":
      "Nombre d'unités - Studio",
    "buildingspec.form-section.field.label.units_in_building_one_bedroom":
      "Nombre d'unités - une chambre",
    "buildingspec.form-section.field.label.units_in_building_two_bedroom":
      "Nombre d'unités - deux chambres",
    "buildingspec.form-section.field.label.units_in_building_three_bedroom":
      "Nombre d'unités - trois chambres",
    "buildingspec.form-section.field.label.units_in_building_four_plus_bedroom":
      "Nombre d'unités - quatre+ chambres",
    "buildingspec.form-section.field.label.total_number_of_units":
      "Total nombre d'unités",
    "buildingspec.form-section.field.label.gross_floor_area":
      "Superficie de plancher brute",
    "buildingspec.form-section.field.label.utilities_included_cable":
      "Services compris - câble",
    "buildingspec.form-section.field.label.utilities_included_ac":
      "Services compris - AC",
    "buildingspec.form-section.field.label.utilities_included_heat":
      "Services compris - chauffage",
    "buildingspec.form-section.field.label.utilities_included_electricity":
      "Services compris - électricité,",
    "buildingspec.form-section.field.label.utilities_included_garbage":
      "Services compris - ordures",
    "buildingspec.form-section.field.label.utilities_included_internet":
      "Services Compris - Internet",
    "buildingspec.form-section.field.label.utilities_included_none":
      "Services compris - aucun",
    "buildingspec.form-section.field.label.lease_terms": "Conditions du bail",
    "buildingspec.form-section.field.label.lot_size": "Taille du lot",
    "buildingspec.form-section.field.label.lot_dimensions": "Dimensions du lot",

    // Retail Building Type
    "buildingspec.form-section.field.label.pta_population":
      "Zone commerciale primaire - population",
    "buildingspec.form-section.field.label.pta_number_of_households":
      "Zone commerciale primaire - Nb de ménages",
    "buildingspec.form-section.field.label.pta_average_household_income":
      "Zone commerciale primaire - revenu moyen par ménage",
    "buildingspec.form-section.field.label.sta_population":
      "Zone commerciale secondaire - population",
    "buildingspec.form-section.field.label.sta_number_of_households":
      "Zone commerciale secondaire - Nb de ménages",
    "buildingspec.form-section.field.label.sta_average_household_income":
      "Zone commerciale secondaire - revenu moyen par ménage",
    "buildingspec.form-section.field.label.cp_median_age":
      "Profil de Consommateur - Âge médian",
    "buildingspec.form-section.field.label.cp_persons_per_household":
      "Profil de consommateur - personnes par ménage",
    "buildingspec.form-section.field.label.cp_average_household_income":
      "Profil de consommateur - revenu moyen par ménage",
    "buildingspec.form-section.field.label.annual_pedestrian_traffic":
      "Circulation piétonnière annuelle",
    "buildingspec.form-section.field.label.demographic_source":
      "Source démographique ",
    "buildingspec.form-section.field.label.operating_hours_monday":
      "Operating Hours - Monday", // TODO
    "buildingspec.form-section.field.label.operating_hours_tuesday":
      "Operating Hours - Tuesday", // TODO
    "buildingspec.form-section.field.label.operating_hours_wednesday":
      "Operating Hours - Wednesday", // TODO
    "buildingspec.form-section.field.label.operating_hours_thursday":
      "Operating Hours - Thursday", // TODO
    "buildingspec.form-section.field.label.operating_hours_friday":
      "Operating Hours - Friday", // TODO
    "buildingspec.form-section.field.label.operating_hours_saturday":
      "Operating Hours - Saturday", // TODO
    "buildingspec.form-section.field.label.operating_hours_sunday":
      "Operating Hours - Sunday", // TODO
    "buildingspec.form-section.field.label.operating_hours_description":
      "Description de Heures d'ouverture",
    "buildingspec.form-section.field.label.number_of_stores": "Nb de magasins",
    "buildingspec.form-section.field.label.food_court": "Foire alimentaire ?",
    "buildingspec.form-section.field.label.number_of_food_units":
      "Nb de comptoirs alimentaires",
    "buildingspec.form-section.field.label.food_court_seating":
      "Places assises de la foire alimentaire",
    "buildingspec.form-section.field.label.total_retail_space_CRU":
      "Superficie totale de l'espace commercial - (CRU)",
    "buildingspec.form-section.field.label.mall_type": "Type centre commercial",

    // Buildings - Building Media Page
    "buildingmedia.contents.no-media.info": "Aucun média disponible",
    "buildingmedia.upload-dialog.title": "Télécharger des médias",
    "buildingmedia.upload-dialog.drop-area.placeholder":
      "Faites glisser et déposez un fichier ici ou cliquez",
    "buildingmedia.upload-dialog.media-details.info.label": "Info",
    "buildingmedia.upload-dialog.media-details.file-name.label":
      "Nom de fichier",
    "buildingmedia.upload-dialog.media-details.title.label": "Titre",
    "buildingmedia.upload-dialog.media-details.alt-text.label":
      "Texte alternatif",
    "buildingmedia.upload-dialog.media-details.uploaded.label": "Téléchargé",
    "buildingmedia.upload-dialog.media-details.tag.label": "Tag",
    "buildingmedia.upload-dialog.media-details.button.set-as-thumbnail.label":
      "Définir comme vignette", 
    "buildingmedia.media-save.success-msg":
      "Les media de batiment ont été mis à jour avec succès!",
    "buildingmedia.media-delete.confirmation-msg":
      "Êtes-vous sûr de vouloir supprimer ce média ?",
    "buildingmedia.thumbnail-unpublish.published-msg":
      "Supprimer l'étiquette 'Thumbnail' pour dépublier l'image", 
    "buildingmedia.media-preview.page.label": "Page", 
    "buildingmedia.media-preview.preview-unavailable": "L'aperçu n'est pas disponible",
    "suitemedia.media-save.success-msg": "Mise à jour réussie de Suite media!",
    "suitemedia.header.page.title": "Médias marketing",

    // Buildings - Building Contacts Page
    "contacts.meta-header.controls.btn.add-contact": "Ajouter le contact",
    "contacts.meta-header.controls.btn.add-leasing-contact":
      "Ajouter le contact leasing", //TODO
    "contacts.meta-header.controls.btn.add-property-contact":
      "Ajouter le contact property", //TODO
    "contacts.list-view.header.name.label": "Nom",
    "contacts.list-view.table.header.phone.label": "Téléphone",
    "contacts.list-view.table.header.email.label": "Courriel",
    "contacts.list-view.table.header.company.label": "Compagnie",
    "contacts.list-view.table.header.title.label": "Titre",
    "contacts.list-view.table.header.actions.label": "Actes",
    "contacts.list-view.contact-delete.confirmation":
      "Êtes-vous sûr de vouloir supprimer le contact?",
    "contacts.list-view.contact-delete.success-msg":
      "Contact supprimé avec succès!",
    "contacts.list-view.contact-update.success-msg":
      "Contact mis à jour avec succès!",
    "contacts.add-new-contact.dialog.title": "Ajouter un contact à bâtiment",
    "contacts.add-new-contact.dialog.instructions":
      "Lorsque vous ajoutez un utilisateur ici, ils deviennent un point de contact pour toutes les listes ajoutées à ce bâtiment.",
    "contacts.add-new-contact.dialog.search.placeholder":
      "Recherche contact...",
    "contacts.add-new-contact.contact-add.success-msg":
      "Contacts added successfully!", //TODO
    "contacts.add-new-contact.dialog.selected-contacts-panel.title":
      "CONTACTS SÉLECTIONNÉS",
    "contacts.add-new-contact.dialog.selected-contacts-panel.no-selections.info":
      "Aucun contact sélectionné",

    // Settings - Your Account
    "settings.context-pane.title.settings.label": "Paramètres",
    "settings.context-pane.tabs.your-account.label": "Votre compte",
    "settings.context-pane.tabs.users.label": "Utilisateurs",
    "settings.context-pane.tabs.company.label": "Entreprise",
    "settings.context-pane.tabs.sharing-content.label": "Partage de contenu",
    "settings.context-pane.tabs.building-specs.label":
      "Spécifications des bâtiments",
    "settings.context-pane.tabs.super-admin.label": "Super administrateur",

    "settings.account.panel-title.label": "INFORMATIONS SUR LE COMPTE",
    "settings.account.username.label": "Nom d'utilisateur",
    "settings.account.realname.label": "Nom usuel",
    "settings.account.phone.label": "Téléphone",
    "settings.account.email.label": "Courriel",

    "settings.account.existing-password.label": "Mot de passe existant",
    "settings.account.new-password.label": "Mot de passe nouveau",
    "settings.account.confirm-password.label": "Confirmation du mot passe",
    "settings.account.save-changes.button": " Enregistrer les modifications",

    "settings.account.readonly.panel-title.label": "VOS INFORMATIONS ACTUELLES",
    "settings.account.last-updated.label": "Dernière mise à jour",

    "settings.account.form.validation.password-no-match":
      "Ne correspond pas au nouveau mot de passe spécifié ci-dessus",
    "settings.account.form.validation.current-pwd":
      "Veuillez entrer le mot de passe actuel",
    "settings.account.form.validation.new-pwd":
      "Veuillez entrer le nouveau mot de passe",
    "settings.account.form.validation.retype-new-pwd":
      "Veuillez entrer à nouveau le nouveau mot de passe",
    "settings.account.password-change.success-msg":
      "Le mot de passe a été changé avec succès!",
    "settings.account.user-update.success-msg":
      "User details updated successfully!", // TODO
    "settings.account.app-version.label": "Version du site",
    // Settings - Users
    "settings.users.users-list-view.table.header.name.label": "Nom",
    "settings.users.users-list-view.table.header.username.label":
      "Nom d'utilisateur",
    "settings.users.users-list-view.table.header.email.label": "Email",
    "settings.users.users-list-view.table.header.role.label": "Rôle",
    "settings.users.users-list-view.table.header.actions.label": "Actions",
    "settings.users.users-list-view.table.header.transfer-from.label":
      "Transfert à partir de",
    "settings.users.users-list-view.table.header.transfer-to.label":
      "Transférer à",
    "settings.users.users-list-view.table.header.copy-from.label": "Copier de",
    "settings.users.users-list-view.table.header.copy-to.label": "Copier à",
    "settings.users.users-list-view.transfer-mode.title":
      "Select Users to Transfer Building Permissions and Contact Settings.", // TODO
    "settings.users.users-list-view.copy-mode.title":
      "Sélectionner les utilisateurs pour copier des bâtiments",
    "settings.users.users-list-view.transfer-mode.confirmation-dialog.title":
      "Confirmation",
    "settings.users.users-list-view.transfer-mode.confirmation.body":
      "En cliquant sur 'Oui', vous confirmez que vous transférez {from}les bâtiments assignés à {to}",
    "settings.users.users-list-view.transfer-mode.success-msg":
      "bâtiments désignés transférés avec succès!",
    "settings.users.users-list-view.user-delete.confirmation":
      "Êtes-vous sûr de vouloir supprimer l'utilisateur",
    // TODO
    "settings.users.users-list-view.search-bar.placeholder-text":
      "Tapez ici pour rechercher un utilisateur",

    // Settings - Users - Add New User
    "settings.users.add-new-user.table.header.building.label": "Bâtiment",
    "settings.users.add-new-user.table.header.address.label": "Addresse",
    "settings.users.add-new-user.table.header.city.label": "Ville",
    "settings.users.add-new-user.page.header.title":
      "Ajouter un nouvel utilisateur",
    "settings.users.add-new-user.step.profile-perm.title":
      "Profil et autorisations",
    "settings.users.add-new-user.step.perm-buildings.title":
      "Bâtiments autorisés",
    "settings.users.add-new-user.section.account-info.title":
      "INFORMATION SUR LE COMPTE",
    "settings.users.add-new-user.section.account-info.form.username.label":
      "Nom d'utilisateur",
    "settings.users.add-new-user.section.account-info.form.email.label":
      "Email",
    "settings.users.add-new-user.section.account-info.form.first-name.label":
      "Prénom",
    "settings.users.add-new-user.section.account-info.form.last-name.label":
      "Nom de famille",
    "settings.users.add-new-user.section.account-info.form.title.label":
      "Title", //TODO
    "settings.users.add-new-user.section.account-info.form.company.label":
      "Compagnie", //TODO
    "settings.users.add-new-user.section.account-info.form.phone.label":
      "Téléphone",
    "settings.users.add-new-user.section.account-info.form.language.label":
      "Langue",
    "settings.users.add-new-user.section.account-info.form.language.E":
      "Anglais",
    "settings.users.add-new-user.section.account-info.form.language.F":
      "Français",
    "settings.users.add-new-user.section.account-info.form.password.label":
      "Mot de passe",
    "settings.users.add-new-user.section.account-info.form.confirm-password.label":
      "Confirmation du mot passe",
    "settings.users.add-new-user.section.user-role.title": "RÔLE D'UTILISATEUR",
    "settings.users.add-new-user.section.user-role.CA.desc":
      "Peut remplacer et modifier manuellement tout le contenu.",
    "settings.users.add-new-user.section.user-role.LA.desc":
      "Peut lire et écrire. Mais ne peut pas remplacer les modifications d'automatisation.",
    "settings.users.add-new-user.section.user-role.LB.desc":
      "Peut lire avec des autorisations d'écriture limitées.",
    "settings.users.add-new-user.section.user-role.LB.sub-section.stacking-plan-access":
      "Accès au plan d'empilage",
    "settings.users.add-new-user.section.user-role.LB.sub-section.stacking-plan-access.RO.label":
      "Lecture seulement",
    "settings.users.add-new-user.section.user-role.LB.sub-section.stacking-plan-access.MP.label":
      "Profil marketing seulement",
    "settings.users.add-new-user.section.perm-buildings.title":
      "BÂTIMENTS AUTORISÉS",
    "settings.users.add-new-user.section.perm-buildings.instructions":
      "Veuillez sélectionner les bâtiments autorisés",
    "settings.users.add-new-user.section.perm-buildings.search.label":
      "Recherche",
    "settings.users.add-new-user.section.perm-buildings.selected-buildings.label":
      "BÂTIMENTS SÉLECTIONNÉS",
    "settings.users.add-new-user.section.perm-buildings.no-selection.label":
      "Aucun bâtiment sélectionné",

    // Settings - User Details View
    "settings.users.users-details.profile.updated.label": "Actualisé",
    "settings.users.users-details.profile.company.label": "Enterprise",
    "settings.users.users-details.profile.title.label": "Titre", //TODO
    "settings.users.users-details.profile.role.label": "Rôle",
    "settings.users.users-details.profile.email.label": "Email",
    "settings.users.users-details.profile.phone.label": "Téléphone",
    "settings.users.users-details.profile.address.label": "Addresse",
    "settings.users.users-details.buildings.assigned-buildings.label":
      "PERMITTED BUILDINGS", // TODO
    "settings.users.users-details.buildings.building.label": "BÂTIMENTS",
    "settings.users.users-details.buildings.client.label": "Client",
    "settings.users.users-details.buildings.since.label": "Depuis",
    "settings.users.users-details.buildings.action.label": "Action",
    "settings.users.users-details.buildings.no-buildings-assigned.info":
      "Aucun bâtiment assignées",
    "settings.users.users-details.role.user-role.label": "Rôle utilisateur",
    "settings.users.users-details.add-buildings-dialog.title":
      "Permitted Buildings", // TODO
    "settings.users.users-details.add-buildings-dialog.sub-title":
      "If no buildings are selected {userFirstName} will have access to the full portfolio.", // TODO
    "settings.users.users-details.add-buildings-dialog.instructions":
      "Please select which buildings {userFirstName} can access.", // TODO
    "settings.users.users-details.add-buildings-dialog.search.label":
      "Recherche",
    "settings.users.users-details.add-buildings-dialog.selected-buildings.label":
      "BÂTIMENTS SÉLECTIONNÉS",
    "settings.users.users-details.add-buildings-dialog.no-buildings-selected.info":
      "Aucun bâtiment sélectionné",
    "settings.users.users-details.delete-building-assignment.confirmation-msg":
      "Are you sure you want to delete this building assignment ?", // TODO

    "virtual_tours.private-toggle.confirmation-msg":
      "Voulez-vous vraiment rendre cette visite virtuelle privée?",
    "virtual_tours.public-toggle.confirmation-msg":
      "Voulez-vous vraiment rendre cette visite virtuelle publique?",
    "virtual_tours.private.label": "Privée",
    "virtual_tours.public.label": "Publique",
    "virtual.tours.none": "Aucune Visite Virtuelle à Montrer",
    "virtual.tours.suite": "Visites Virtuelles de la Suite",
    "virtual_tours.optional.placeholder": "(Optionnelle)",
    "virtual_tours.alert.update-success.msg": "Mise à jour réussie",
    "virtual_tours.delete.confirmation-msg":
      "Êtes-vous sûr de vouloir supprimer cette visite virtuelle?",
    "virtual_tours.validation.message":
      'Assurez-vous d\'ajouter "https: //" ou "http: //" à votre URL pour qu\'elle apparaisse correctement dans vos pages de location.'
  }
};

export default messages;
